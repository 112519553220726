import React from "react";
import './ls-home-newsletter.css';

import unaResidenceLogo from '../../../assets/home/una-residence-logo-2.svg';

export default function LsHomeNewsletter(props) {
  // part of code that renders
  return (
    <>  
      {/* large screen home newsletter container */}
      <div className="ls-home-newsletter-container" style={{marginTop: props.marginTop + 'vw'}}>

        {/* large screen home newsletter container container*/}
        <div className="ls-home-newsletter-container-container">

          {/* large screen home newsletter logo container*/}
          <div className="ls-home-newsletter-logo-container">
            <img 
              src={unaResidenceLogo}
              onContextMenu={(e) => e.preventDefault(e)}
              alt="Una Residence Poliklinika"
              onClick={props.reloadHomePage}/>
          </div>

          {/* large screen home newsletter text & input & button container*/}
          <div className="ls-home-newsletter-text-input-button-container">

            {/* large screen home newsletter text */}
            <p className="ls-home-newsletter-text">
              Prijavite se za Newsletter, postanite član kluba i ostvarite posebne akcije i pogodnosti u Una Residence Poliklinici.
            </p>

            {/* large screen home newsletter input & button container*/}
            <form 
              className="ls-home-newsletter-input-button-container"
              method="POST"
              action="https://formsubmit.co/poliklinika@unaresidence.rs"
              id="ls-home-newsletter-form-container">

              {/* large screen home newsletter input */}
              <input 
                className="ls-home-newsletter-input"
                placeholder="Vaša Email adresa"
                type="email"
                name="Newsletter"/>

              {/* large screen home newsletter button */}
              <button
                className="ls-home-newsletter-button"
                type="submit"
                form="ls-home-newsletter-form-container">
                  POŠALJI
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}