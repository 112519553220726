const lsHomeQuestionsData = [
  {
    id: 1,
    question: 'Koji je najbolji tretman za mene?',
    answer: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
  },
  {
    id: 2,
    question: 'Kako izabrati anti-aging tretman?',
    answer: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
  },
  {
    id: 3,
    question: 'Koji je najefektivniji laser tretman?',
    answer: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
  },
  {
    id: 4,
    question: 'Kako izgleda dermatološki pregled?',
    answer: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
  },
]

export default lsHomeQuestionsData;