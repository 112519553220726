import React from "react";
import './part-5.css';
import QuestionPart5 from "../question-part-5/question-part-5.js";
import girlFace from '../../../assets/home/girl-face.webp';
import NewsletterPart5 from "../newsletter-part-5/newsletter-part-5.js";

export default function Part5(props) {

  /* starting state value */
  const [activeQuestion, setActiveQuestion] = React.useState(false);

  /* data with titles and paragraphs */
  const [questionData, setQuestionData] = React.useState([
    {
      title: 'Koji je najbolji tretman za mene?',
      paragraphs: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
    },
    {
      title: 'Kako izabrati anti-aging tretman?',
      paragraphs: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
    },
    {
      title: 'Koji je najefektivniji laser tretman?',
      paragraphs: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
    },
    {
      title: 'Kako izgleda dermatološki pregled?',
      paragraphs: 'Odgovori na najčešća pitanja će uskoro biti dostupni.'
    }
  ])

  /* function resposible to change value of activeQuestion state */
  const handleQuestionClick = (index) => {
    setActiveQuestion((prevIndex) => (prevIndex === index ? false : index));;
  };

  /* part of code that renders */
  return (
    <div>

      {/* small screen part 5 container */}
      <div className="ss-part5-container">

        {/* small screen part 5 container questions */}
        <div className="ss-part5-container-questions">

          {/* small screen part 5 questions container */}
          <div className="ss-part5-questions-container">

            {/* small screen part 5 questions image */}
            <div className="ss-part5-questions-image">
              <img 
                src={girlFace}
                onContextMenu={(e) => e.preventDefault()}
                alt="una-residence-poliklinika-lice" />
            </div> 

            {/* small screen part 5 questions question */}
            <div className="ss-part5-questions-question">
              {questionData.map((item, index) => {
                return (
                  <QuestionPart5 
                    key={index}
                    title={item.title}
                    paragraphs={item.paragraphs}
                    isActive={index === activeQuestion}
                    onClick={() => handleQuestionClick(index)}
                  />
                )
              })}
            </div> 
          </div>      
        </div>

        <NewsletterPart5 />

      </div>

    </div>
  )
}