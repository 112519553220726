import React from "react";
import './ls-blog-marketing.css';

export default function LsBlogMarketing({marketingPost, marginTop}) {
  // part of code that renders
  return (
    <>
      {/* large screen blog right marketing container */}
      <a href={marketingPost.link}>
        <div 
          className="ls-blog-right-marketing-container"
          style={{marginTop: marginTop + 'vw'}}>
            <img
              src={marketingPost.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={marketingPost.alternative}/>
        </div>
      </a>
    </>
  )
}