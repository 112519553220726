import React from "react";
import './doctor-doctors.css'
import { Link } from "react-router-dom";

export default function DoctorDoctors({doctor, reloadPage}) {

  /* part of code that renders */
  return (
    <>
      
      {/* small screen doctors doctor container */}
      <Link to={doctor.link} style={{textDecoration: 'none'}}>
        <div className="ss-doctors-doctor-container" onClick={reloadPage} // reloadPage function passed from main doctor profile page (example: ana-malivukovic.js)
        >

          {/* small screen doctors doctor image container */}
          <div className="ss-doctors-doctor-image-container">
            <img 
              src={doctor.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={doctor.alternative}/>
          </div>

          {/* small screen doctors doctor name & paragraph container */}
          <div className="ss-doctors-doctor-name-paragraph-container">
            {/* small screen doctors doctor name */}
            <p className="ss-doctors-doctor-name">
              {doctor.name}
            </p>
            {/* small screen doctors doctor paragraph */}
            <p className="ss-doctors-doctor-paragraph">
              {doctor.description}
            </p>
          </div>

        </div>
      </Link>

    </>
  )
}