import React from "react";
import './newsletter-doctors.css'
import unaResidenceLogo2 from '../../../assets/home/una-residence-logo-2.svg';

export default function NewsletterDoctors(props) {

  /* part of code that renders */
  return(
    <div>

      {/* small screen doctors newsletter container */}
      <div 
        className="ss-doctors-newsletter-container"
        style={{marginTop: props.marginTop + 'vh'}}>

        {/* small screen doctors newsletter container container */}
        <div className="ss-doctors-newsletter-container-container">
          {/* small screen doctors newsletter logo */}
          <div className="ss-doctors-newsletter-logo">
            <img src={unaResidenceLogo2} />
          </div>

          {/* small screen doctors newsletter paragraph */}
          <div className="ss-doctors-newsletter-paragraph">
            <p>
              Prijavite se za Newsletter, postanite član kluba i ostvarite posebne akcije i pogodnosti u Una Residence Poliklinici
            </p>
          </div>

          {/* small screen doctors newsletter input & button */}
          <div className="ss-doctors-newsletter-input-button">

          <form
            method="POST"
            action="https://formsubmit.co/unaresidence.rs@gmail.com">
            {/* small screen doctors newsletter input */}
            <input 
              className="ss-doctors-newsletter-input"
              placeholder="vaša e-mail adresa"
              type="email"
              name="Newsletter"/>

            {/* small screen doctors newsletter button */}
            <button className="ss-doctors-newsletter-button">pošalji</button>
          </form>
          
          </div>
        </div>

      </div>

    </div>
  )
}