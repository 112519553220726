import React from "react";
import './branislav-lukic.css';

import HeaderDoctors from "../../components/doctors/header-doctors/header-doctors.js";
import NavigationDropdownDoctors from "../../components/doctors/navigation-dropdown-doctors/navigation-dropdown-doctors.js";
import LsEmployeProfile from "../../components/employe/ls-employe-profile/ls-employe-profile.js";
import SsEmployeProfile from "../../components/employe/ss-employe-profile/ss-employe-profile.js";

import lsEmployeProfileData from "../../assets/data/ls-employe-profile/ls-employe-profile-data.js";

export default function BranislavLukic() {

  /* dropdown state value that changes by showDropdown function */
  const [dropdown, setDropdown] = React.useState(
    {
      isOn: false
    }
  )
  
  /* function changes value of dropdown state and is passed to HeaderDoctors component */
  function showDropdown() {
    setDropdown((prevState) => {
      return(
        {isOn: !prevState.isOn}
      )
    })
  }

  /* reload page function */
  function reloadPage() {
    window.location.reload();
  }

  // profileId const for recognizing which object to render from ls-employe-profile-data
  const profileId = 'branislav-lukic';    

  /* part of code that renders */
  return (
    <>
      
      {/* small screen branislav lukic container */}
      <div 
        className="ss-branislav-lukic-container"
        /* change background and height (to be fullscreen) depending on dropdown state value */
        style={dropdown.isOn ? {backgroundColor:'white', height:'100vh'} : {backgroundColor:'white'}}>

        {/* small screen header doctors component */}
        <HeaderDoctors 
          showDropdown={showDropdown} /* passing showDropdown function to HeaderDoctors component to be used on click */
          isActive={dropdown.isOn} /* passing dropdown state value to HeaderDoctors changing header icon that depends on that value */ />

        {/* render part of code depend of dropdown state value */}
        {dropdown.isOn ? <NavigationDropdownDoctors /> :

        <div className="ss-branislav-lukic-container-container">
          <SsEmployeProfile 
            id={profileId}
            reloadPage={reloadPage}/>
        </div>
        }
      </div>










      {/* large screen branislav lukic container */}
      <div className="ls-branislav-lukic-container">

        {lsEmployeProfileData.map((profile) => {
          if (profile.id === 'branislav-lukic') {
            return (
              <LsEmployeProfile 
                key={profile.id}
                profile={profile}/>
            )
          }
        })}
      </div>

    </>
  )
}