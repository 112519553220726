import React from "react";
import './employe-services-list-box.css';

export default function EmployeServicesListBox(props) {

  /* part of code that renders */
  return (

    /* small screen employe services list box container */
    <div className="ss-employe-services-list-box-container">
      <p>{props.text}</p>
    </div>

  )
}