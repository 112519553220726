import React from "react";
import './navigation-dropdown-icons-doctors.css';

export default function NavigationDropdownIconsDoctors(props) {

  /* part of code that renders */
  return(
    <div>
      
      {/* small screen doctors navigation dropdown icons */}
      <a href={props.url} target="_blank">
        <div className="ss-doctors-navigation-dropdown-icons">
          <img 
            src={props.source}
            onContextMenu={(e) => {e.preventDefault()}}
            alt={props.aletrnative}/>
        </div>
      </a>
    </div>
  )
}