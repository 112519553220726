import React from "react";
import './footer-home.css'
import candelaLogo from '../../../assets/home/candela-logo.svg'
import unaResidenceLogo from '../../../assets/home/una-residence-logo.svg'
import iconFooterInstagram from "../../../assets/home/icon-footer-instagram.svg"
import iconFooterFacebook from "../../../assets/home/icon-footer-facebook.svg"
import iconFooterYoutube from "../../../assets/home/icon-footer-youtube.svg"
import iconFooterLinkedin from "../../../assets/home/icon-footer-linkedin.svg"
import iconFooterGoogle from "../../../assets/home/icon-footer-google.svg"
import IconsFooter from "../icons-footer/icons-footer.js";

export default function FooterHome(props) {

  /* part of code that renders */
  return (
    <div>
      
      {/* small screen footer container */}
      <div className="ss-footer-container" style={{backgroundColor: props.backgroundColor}}>

        <div className="ss-footer-container-container">
        
        {/* small screen footer container una logo */}
        <div className="ss-footer-container-una-logo">
          <img
            onContextMenu={(e) => e.preventDefault()} 
            src={unaResidenceLogo}
            alt="Una Residence Poliklinika" 
            onClick={props.reloadHomePage}/>
        </div>

          
        {/* small screen footer container candela logo */}
        <div className="ss-footer-container-candela-logo">
          <img
            onContextMenu={(e) => e.preventDefault()} 
            src={candelaLogo}
            alt="candela-logo" />
        </div>

        {/* small screen footer container paragraph */}
        <div className="ss-footer-container-paragraph">
          <p>
            UNA RESIDENCE POLIKLINIKA
            <br />
            CANDELA CENTER OF EXCELLENCE
            <br />
            <br />
            <a 
              href="https://maps.app.goo.gl/aF2APFvhr41o7Kg78"     
              target="_blank">
              VOJVODE MILENKA 8, SAVSKI VENAC
              <br />
              KNEZA MILOŠA RESIDENCE
            </a>
            <br />
            <br />
            <a href="tel:+3810117703018">
              +381 011 770-30-18
            </a>
            <br />
            <a href="tel:+381666703018">
              +381 66 670-30-18
            </a>
            <br />
            <br />
            <a href="mailto:poliklinika@unaresidence.rs">
              POLIKLINIKA@UNARESIDENCE.RS
            </a>
          </p>
        </div>

        {/* small screen footer container icons */}
        <div className="ss-footer-container-icons">
          <IconsFooter 
            image={iconFooterInstagram}
            url="https://www.instagram.com/unaresidence.rs/"
            alt="instagram-icon"
          />
          <IconsFooter 
            image={iconFooterFacebook}
            url="https://www.facebook.com/unaresidence.rs"
            alt="facebook-icon"
          />
          <IconsFooter 
            image={iconFooterYoutube}
            url="https://www.youtube.com/@unaresidence"
            alt="youtube-icon"
          />
          <IconsFooter 
            image={iconFooterLinkedin}
            url="https://www.linkedin.com/company/unaresidence"
            alt="linkedin-icon"
          />
          <IconsFooter 
            image={iconFooterGoogle}
            url="https://www.google.com/maps/place/Una+Residence+Poliklinika/@44.8043021,20.4565232,15z/data=!4m6!3m5!1s0x475a71e9b6da3cc3:0x6b543580976de891!8m2!3d44.8043021!4d20.4565232!16s%2Fg%2F11vb19xw01?entry=ttu"
            alt="google-business-icon"
          />
        </div>

        {/* small screen footer container copyright */}
        <div className="ss-footer-container-copyright">
          <p>
            <strong>Oratorri Comunicazioni</strong>
            <br />
            Copyright © 2024 Una RESIDENCE
            <br />
            Sva prava zadržana.
          </p>
        </div>

        </div>

      </div>

    </div>
  )
}