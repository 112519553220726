import React from "react";
import './arrow-up-icon.css';

import arrowUpIcon from '../../assets/home/una-residence-poliklinika-arrow-up-icon-1.svg';

export default function ArrowUpIcon() {

  // scroll to top of the page function
  function scrollTop() {
    window.scrollTo(0, 0);
  }

  // part of code that renders
  return (

    <div>

      {/* small screen arrow up icon container */}
      <div 
        className="ss-arrow-up-icon-container"
        onClick={scrollTop}> {/* activate scrollTop function on click */}
          <img 
            src={arrowUpIcon} 
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-strelica"/>
      </div>










      {/* large screen arrow up icon container */}
      <div 
        className="ls-arrow-up-icon-container"
        onClick={scrollTop}> {/* activate scrollTop function on click */}
          <img 
            src={arrowUpIcon} 
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-strelica"/>
      </div>

    </div>

  )
}