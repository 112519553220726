import React from "react";
import './navigation-dropdown-list-item-doctors.css'

export default function NavigationDropdownListItemDoctors(props) {

  /* part of code that renders */
  return (
    <div>
      
      {/* small screen doctors navigation dropdown list item container */}
      <div 
        className="ss-doctors-navigation-dropdown-list-item"
        onClick={props.reloadPage} > {/* passing reloadPage from Doctors page trough NavigationDropdownDoctors component */}
        <h1>{props.title}</h1>
      </div>

    </div>
  )
}