import React from "react";
import './ls-home-part-3.css';
import { Link } from "react-router-dom";

import lsHomeServicesFieldData from "../../../assets/data/ls-home/ls-home-services-field-data.js";

import LsHomePart3Field from "../ls-home-part-3-field/ls-home-part-3-field.js";

export default function LsHomePart3(props) {

  function devideArray(array, chunkSize) {
    const chunks = [];
    let id = 1;
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push({
        id: id++,
        data: array.slice(i, i + chunkSize)
      })
    }
    return chunks;
  }

  const lsHomeServicesFieldDataDevided = devideArray(lsHomeServicesFieldData, 2);

  // part of code that renders
  return (
    <>
      {/* large screen home part 3 container */}
      <div className="ls-home-part-3-container" style={{background: props.background}}>
        
        {/* large screen home part 3 services button */}
        <Link to='/services' style={{textDecoration:'none'}} onClick={props.reloadServicesPage}>
          <div className="ls-home-part-3-services-button">
            <p>Usluge</p>
          </div>
        </Link>

        {/* large screen home part 3 text container */}
        <div className="ls-home-part-3-text-container">

          {lsHomeServicesFieldDataDevided.map((column) => {
            return (
              // large screen home part 3 text container container
              <div 
                className="ls-home-part-3-text-container-container"
                key={column.id}>
                  {column.data.map((textGroup) =>  {
                    return (
                      <LsHomePart3Field 
                        key={textGroup.id}
                        textGroup={textGroup}/>
                    )
                  })}
              </div>
            )
          })}

        </div>
       

      </div>
    </>
  )
}