import React from "react";
import './part-2.css'
import unaPoliklinikaLogo from "../../../assets/home/una-poliklinika-logo.svg"

export default function Part2() {

  /* sends text from editableDiv to hidden textarea */
  function getContent() {
    let textarea = document.querySelector('.ss-part2-container-form-txtarea');
    let editableDiv = document.querySelector('.ss-part2-container-form-message');
    textarea.value = editableDiv.innerHTML;
  }

  const handleInvalid = (e) => {
    e.target.setCustomValidity('Molimo Vas popunite prazno polje.');
  };

  const handleInput = (e) => {
    e.target.setCustomValidity('');
  };

  /* part code that renders */
  return (

    <div>

      {/* small screen part 2 container */}
      <div className="ss-part2-container">

        {/* small screen part 2 container container */}
        <div className="ss-part2-container-container">

          {/* small screen part 2 container paragraph */}
          <div className="ss-part2-container-paragraph">
            <p>
              <strong>
                Postavite nam pitanje<br />
                ili zakažite pregled.
              </strong>
            </p>
          </div>

          {/* small screen part 2 container container container */}
          <div className="ss-part2-container-container-container">

            {/* small screen part 2 container decoration */}
            <div className="ss-part2-container-decoration">

            </div>
            
            {/* small screen part 2 container form */}
            <div className="ss-part2-container-form">

              {/* small screen part 2 container form container */}
              <form
                method="POST"
                action="https://formsubmit.co/poliklinika@unaresidence.rs"
                id="ss-part2-container-form" 
                className="ss-part2-container-form-container">
                
                {/* small screen part 2 container from logo */}
                <div className="ss-part2-container-form-logo">
                  <img 
                    onContextMenu={(e) => e.preventDefault()}
                    src={unaPoliklinikaLogo}
                    alt="Una Residence Poliklinika" />
                </div>
                
                {/* small screen part 2 container form input */}
                <div className="ss-part2-container-form-input">
                  <p>Ime</p>
                  <input
                    type="text" 
                    placeholder="Ime"
                    name="Ime"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>

                <div className="ss-part2-container-form-input">
                  <p>Prezime</p>
                  <input
                    type="text"  
                    placeholder="Prezime"
                    name="Prezime"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>

                <div className="ss-part2-container-form-input">
                  <p>Email adresa</p>
                  <input
                    type="email"  
                    placeholder="vaša@emailadresa.com"
                    name="Email"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>

                <div className="ss-part2-container-form-input">
                  <p>Broj telefona</p>
                  <input
                    type="number"  
                    placeholder="Vaš broj telefona"
                    name="Telefon"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>

                <div className="ss-part2-container-form-input">
                  <p>Poruka</p>
                  <div 
                    className="ss-part2-container-form-message"
                    contentEditable="true" 
                    placeholder="Tekst poruke">
                  </div>
                  {/* text area hidden */}
                  <textarea
                    name="Poruka"
                    className="ss-part2-container-form-txtarea"/>
                </div>

              </form>

            </div>

            {/* small screen part 2 container button */}
            <button
              type="submit"
              form="ss-part2-container-form"
              className="ss-part2-container-button"
              onClick={getContent} /* sends text from editableDiv to hidden textarea */
              >POŠALJI
            </button>

          </div>

        </div>      

      </div>

    </div>

  )
}