import React from "react";

import Post from "../../components/post/post";

import blogData from '../../assets/data/ls-blog/ls-blog-data';

export default function BlogPost2() {
  // part of code that renders
  return (
    <>
      {blogData.map((post, index) => {
        if (post.postId === 2) {
          return (
            <Post 
              key={post.postId}
              // passing object info from blogData to post component
              post={blogData[index]}/>
          )
        }
      })}
    </>
  )
}