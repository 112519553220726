import React from "react";
import './employe-services-list.css';

import EmployeServicesListBox from "../employe-services-list-box/employe-services-list-box.js";

export default function EmployeServicesList(props) {

  /* part of code that renders */
  return (

    /* small screen employe services list container */
    <div 
      className="ss-employe-services-list-container"
      style={{marginTop: props.marginTop + 'vh'}}>

        {props.servicesList.map(item => {
          return (
            <EmployeServicesListBox 
              key={item.id}
              text={item.text}/>
          )
        })}

    </div>

  )
}
