import React from "react";
import './employe-biography.css';

export default function EmployeBiography(props) {

  /* part of code that renders */
  return (

    /* small screen employe biography container */
    <div 
      className="ss-employe-biography-container"
      style={{marginTop: props.marginTop + 'vh'}}>
        
        {/* small screen employe biography title container */}
        <div className="ss-employe-biography-title-container">
          <p>Biografija</p>
        </div>

        {/* small screen employe biography paragraph */}
        {props.biography.map((part) => {
          if (part.id === 'mainBio') {
            return part.content.map((listItem) => {
              return (
                <p className="ss-employe-biography-paragraph" key={listItem.id}>{listItem.text}</p>
              )
            })
          } else if (part.id === 'education') {
            return (
              <div key={part.id}>
                <p className="ss-employe-biography-subtitle">Obrazovanje</p>
                <ul>
                  {part.content.map((listItem) => {
                    return (
                      <li 
                        key={listItem.id}>
                          {listItem.text}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          } else if (part.id === 'experience') {
            return (
              <div key={part.id}>
                <p className="ss-employe-biography-subtitle">Iskustvo</p>
                <ul>
                  {part.content.map((listItem) => {
                    return (
                      <li 
                        key={listItem.id}>
                          {listItem.text}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          } else if (part.id === 'membership') {
            return (
              <div key={part.id}>
                <p className="ss-employe-biography-subtitle">Članstvo</p>
                <ul>
                  {part.content.map((listItem) => {
                    return (
                      <li 
                        key={listItem.id}>
                          {listItem.text}
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          }
        })}

    </div>

  )
}