import React from "react";
import './ls-home-part-2.css';

import unaLogo from '../../../assets/home/una-poliklinika-logo.svg';

export default function LsHomePart2(props) {

  /* sends text from editableDiv to hidden textarea */
  function getContent() {
    let textarea = document.querySelector('.ls-home-part-2-form-message-textarea');
    let editableDiv = document.querySelector('.ls-home-part-2-form-message-textarea-div');
    textarea.value = editableDiv.innerHTML;
  }

  const handleInvalid = (e) => {
    e.target.setCustomValidity('Molimo Vas popunite prazno polje.');
  };

  const handleInput = (e) => {
    e.target.setCustomValidity('');
  };

  // part of code that renders
  return (
    <>
      {/* large screen home part 2 container */}
      <div className="ls-home-part-2-container">

        {/* large screen home part 2 container container */}
        <div className="ls-home-part-2-container-container">

          {/* large screen home part 2 text container */}
          <div className="ls-home-part-2-text-container">
            <p>Postavite<br/>nam pitanje<br/>ili zakažite<br/>pregled</p>
          </div>

          {/* large screen home part 2 decoration & form container */}
          <div className="ls-home-part-2-decoration-form-container">

            {/* large screen home part 2 decoration */}
            <div className="ls-home-part-2-decoration"></div>   

            {/* large screen home part 2 form container */}
            <form 
              className="ls-home-part-2-form-container"
              method="POST"
              action="https://formsubmit.co/poliklinika@unaresidence.rs"
              id="ls-home-part-2-form-container">

              {/* large screen home part 2 form name & email container */}
              <div className="ls-home-part-2-form-name-email-container">

                {/* large screen home part 2 form input container */}
                <div className="ls-home-part-2-form-input-container">
                  <p>Ime</p>
                  <input 
                    placeholder="Ime"
                    type="text"
                    name="Ime"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>

                {/* large screen home part 2 form input container */}
                <div className="ls-home-part-2-form-input-container">
                  <p>Email adresa</p>
                  <input 
                    placeholder="vaša@emailadresa.com"
                    type="email"
                    name="Email"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>
              </div>

              {/* large screen home part 2 form surname & phonenumber container */}
              <div className="ls-home-part-2-form-surname-phonenumber-container">

                {/* large screen home part 2 form input container */}
                <div className="ls-home-part-2-form-input-container">
                  <p>Prezime</p>
                  <input 
                    placeholder="Prezime"
                    type="text"
                    name="Prezime"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>

                {/* large screen home part 2 form input container */}
                <div className="ls-home-part-2-form-input-container">
                  <p>Broj telefona</p>
                  <input 
                    placeholder="Broj telefona"
                    type="number"
                    name="Telefon"
                    required
                    onInvalid={handleInvalid}
                    onInput={handleInput}/>
                </div>
              </div> 

              {/* large screen home part 2 form message container */}
              <div className="ls-home-part-2-form-message-container">
                <p>Poruka</p>
                <div 
                  className="ls-home-part-2-form-message-textarea-div"
                  contentEditable="true"
                  placeholder="Tekst poruke">
                </div>
                <textarea
                  className="ls-home-part-2-form-message-textarea" 
                  name="Poruka"/>
              </div>

              {/* large screen home part 2 form logo & button container */}
              <div className="ls-home-part-2-form-logo-button-container">

                {/* large screen home part 2 form logo */}
                <img 
                  className="ls-home-part-2-form-logo"
                  src={unaLogo}
                  onContextMenu={(e) => {e.preventDefault(e)}}
                  alt="Una Residence Poliklinika"
                  onClick={props.reloadHomePage}/>

                {/* large screen home part 2 form button */}
                <button 
                  className="ls-home-part-2-form-button"
                  type="submit"
                  form="ls-home-part-2-form-container"
                  onClick={getContent}>
                    <p>POŠALJI</p>
                </button> 
              </div> 
            </form>      
          </div>
        </div>
      </div>
    </>
  )
}