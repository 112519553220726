import React from "react";
import { Link } from "react-router-dom";
import './part-4.css';

import lsHomeTeamData from "../../../assets/data/ls-home/ls-home-team-data";

import DoctorDoctors from "../../doctors/doctor-doctors/doctor-doctors";

export default function Part4() {

  /* part of code that renders */
  return (
    <>

      {/* small screen home part 4 container */}  
      <div className="ss-home-part-4-container">
        
        {/* small screen home part 4 button container */}
        <Link to='/doctors' style={{textDecoration: 'none'}}>
          <div className="ss-home-part-4-button-container">
            <p>Doktori</p>
          </div>
        </Link>

        {lsHomeTeamData.map((doctor) => {
          return (
            <DoctorDoctors 
              key={doctor.id}
              doctor={doctor}
            />
          )
        })}
      </div>

    </>
  )
}