import React from "react";
import './employe-navigation-icons.css';

import EmployeNavigationOneIcon from "../employe-navigation-one-icon/employe-navigation-one-icon.js";

import employeAskIcon from "../../../assets/employe/una-residence-poliklinika-employe-ask-icon.svg";
import employeBiographyIcon from "../../../assets/employe/una-residence-poliklinika-employe-biography-icon.svg";
import employeServicesIcon from "../../../assets/employe/una-residence-poliklinika-employe-services-icon.svg";
import employeGalleryIcon from "../../../assets/employe/una-residence-poliklinika-employe-gallery-icon.svg";

export default function EmployeNavigationIcons(props) {

  /* part of code that renders */
  return(

    /* small screen employe navigation icons container */
    <div 
      className="ss-employe-navigation-icons-container"
      style={{marginTop: props.marginTop + 'vh'}}>

        <EmployeNavigationOneIcon 
          source={employeAskIcon}
          alternative='una-residence-poliklinika-ask-icon'
        />
        <EmployeNavigationOneIcon 
          source={employeBiographyIcon}
          alternative='una-residence-poliklinika-biography-icon'
        />
        <EmployeNavigationOneIcon 
          source={employeServicesIcon}
          alternative='una-residence-poliklinika-services-icon'
        />
        <EmployeNavigationOneIcon 
          source={employeGalleryIcon}
          alternative='una-residence-poliklinika-gallery-icon'
        />

    </div>

  )
}