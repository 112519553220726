import React from "react";
import './ls-home-questions-question.css';

import arrowDown from '../../../assets/home/index-part5-icon-arrow-down.svg';
import arrowUp from '../../../assets/home/index-part5-icon-arrow-up.svg';

export default function LsHomeQuestionsQuestion({question, handleQuestionClick, isActive}) {
  // part of code that renders
  return (
    <>
      {/* large screen home questions question container */}
      <div className="ls-home-questions-question-container">

        {/* large screen home question title container */}
        <div 
          className="ls-home-question-title-container"
          onClick={handleQuestionClick}
          style={{
            ...(question.id === 1 ? {borderTop: '0.260vw solid'} : {}),
            ...(isActive ? {borderBottom: 'none'} : {})
          }}
          >
            <p>{question.question}</p>
            
            {/* large screen home question arrow container */}
            <div className="ls-home-question-arrow-container">
              <img 
                src={isActive ? arrowUp : arrowDown}/>
            </div>
        </div>

        {/* large screen home question answer container */}
        {isActive &&
        <div 
          className="ls-home-question-answer-container"
          style={isActive ? {borderBottom: '0.260vw solid'} : {}}>
            <p>{question.answer}</p>
        </div>
        }
      </div>
    </>
  )
}