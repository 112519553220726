import React from "react";
import './part-1.css';

import candelaLogo from "../../../assets/home/candela-logo.svg";
import imagePart1 from '../../../assets/home/ls-home-part-1-image.webp';

export default function Part1() {

  /* code that renders */
  return (
    <>
      
      {/* small screen home part 1 container */}
      <div className="ss-home-part-1-container">

        {/* small screen home part 1 container container */}
        <div className="ss-home-part-1-container-container">

          {/* small screen home part 1 text */}
          <p className="ss-home-part-1-text">
            Edukativni laser centar za dermatologiju, estetsku i plastičnu hirurgiju
          </p>

          {/* small screen home part 1 logo container */}
          <div className="ss-home-part-1-logo-container">
            <img 
              src={candelaLogo}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-residence-poliklinika-candela-logo"/>
          </div>

          {/* small screen home part 1 button */}
          <a href="tel:+381666703018">
            <div className="ss-home-part-1-button">
              <p>ZAKAŽITE</p>
            </div>
          </a>

          {/* small screen home part 1 image container */}
          <div className="ss-home-part-1-image-container">
            <img 
              src={imagePart1}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-residence-poliklinika-naslovna-slika"/>

            {/* small screen home part 1 decoration 1 */}
            <div className="ss-home-part-1-decoration-1"></div>

            {/* small screen home part 1 decoration 2 */}
            <div className="ss-home-part-1-decoration-2"></div>
          </div>
        </div>
      </div>

    </>
  )

}