import React from "react";
import './ls-home-questions.css';

import girlFace from '../../../assets/home/girl-face.webp';

import LsHomeQuestionsQuestion from "../ls-home-questions-question/ls-home-questions-question.js";

import lsHomeQuestionsData from "../../../assets/data/ls-home/ls-home-questions-data.js";

export default function LsHomeQuestion() {

  const [activeQuestion, setActiveQuestion] = React.useState(false);

  /* function resposible to change value of activeQuestion state */
  const handleQuestionClick = (index) => {
    setActiveQuestion((prevIndex) => (prevIndex === index ? false : index));
  };

  // part of code that renders
  return (
    <>
      {/* large screen home question container */}
      <div className="ls-home-question-container">

        {/* large screen home question container container */}
        <div className="ls-home-question-container-container">

          {/* large screen home question decoration */}
          <div className="ls-home-question-decoration"></div>

          {/* large screen home question image container */}
          <div className="ls-home-question-image-container">
            <img 
              src={girlFace}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-residence-poliklinika-lice"/>
          </div>

          {/* large screen home question text container */}
          <div className="ls-home-question-text-container">
            {lsHomeQuestionsData.map((question, index) => {
              return (
                <LsHomeQuestionsQuestion 
                  key={index}
                  question={question}
                  isActive={index === activeQuestion}
                  handleQuestionClick={() => handleQuestionClick(index)}/>
              )
            })}
          </div>  
        </div>    
      </div>
    </>
  )
}