import post1Image1 from '../../post/post1/una-residence-post-image-1.jpg';
import post1Image2 from '../../post/post1/una-residence-post-image-2.jpg';
import post1Image3 from '../../post/post1/una-residence-post-image-3.png';

// list of marketing posts
const blogMarketingData = [
  {
    id: 1,
    image: post1Image1,
    altertnative: '',
    link: 'https://unaresidence.rs/'
  },
  {
    id: 2,
    image: post1Image3,
    altertnative: '',
    link: 'https://www.instagram.com/unaresidence.rs/'
  },
]

export default blogMarketingData;