import React from "react";
import './ls-employe-profile-slideshow.css';

import arrowLeft from '../../../assets/employe/una-residence-poliklinika-employe-profile-gallery-arrow-left.svg';
import arrowRight from '../../../assets/employe/una-residence-poliklinika-employe-profile-gallery-arrow-right.svg';
import xIcon from '../../../assets/employe/una-residence-poliklinika-gallery-x-icon.svg';

export default function LsEmployeProfileSlideshow({startIndex, profileGallery, closeSlide}) {

  // state value that determine which image will be the first one in slideshow - passed from EmployeProfile component
  const [currentIndex, setCurrentIndex] = React.useState(startIndex);

  // function is changing currentIndex state value to determine which image will be shown in slideshow
  function previousImage() {
    const newIndex = currentIndex === 0 ? profileGallery.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  // function is changing currentIndex state value to determine which image will be shown in slideshow
  function nextImage() {
    const newIndex = currentIndex === profileGallery.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }


  // part of code that renders
  return (
    <>
      {/* large screen employe profile slideshow background container */}
      <div className="ls-employe-profile-slideshow-background-container">

        {/* large screen employe profile slideshow container */}
        <div 
          className="ls-employe-profile-slideshow-container"
          style={{backgroundImage: `url(${profileGallery[currentIndex].image})`}}>
            
            {/* large screen employe profile slideshow left arrow container */}
            <div 
                className="ls-employe-profile-slideshow-arrow-container ls-employe-profile-slideshow-left-arrow">
                  <img 
                    src={arrowLeft}
                    onClick={previousImage}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-strelica"/>
            </div>

            {/* large screen employe profile non visible image container (just determines the height of slideshow container) */}
            <img
              className="ls-employe-profile-slideshow-container-hiddne-image" 
              src={profileGallery[currentIndex].image} />

            {/* large screen employe profile slideshow right arrow container */}
            <div 
              className="ls-employe-profile-slideshow-arrow-container ls-employe-profile-slideshow-right-arrow">
                <img 
                    src={arrowRight}
                    onClick={nextImage}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-strelica"/>
            </div>

            {/* large screen employe profile slideshow x icon container */}
            <div 
              className="ls-employe-profile-slideshow-x-icon-container">
                <img 
                  src={xIcon}
                  onClick={closeSlide}
                  onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-ikonica-x"/>
            </div>
        </div>
      </div>
    </>
  )
}