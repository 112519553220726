import React from "react";
import './contact.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import HeaderDoctors from "../../components/doctors/header-doctors/header-doctors.js";
import NavigationDropdownDoctors from "../../components/doctors/navigation-dropdown-doctors/navigation-dropdown-doctors.js";
import Part2 from "../../components/home/part-2/part-2.js";
import FooterHome from "../../components/home/footer-home/footer-home.js";

import LsHomeHeader from "../../components/ls-home/ls-home-header/ls-home-header.js";
import LsHomePart2 from "../../components/ls-home/ls-home-part-2/ls-home-part-2.js";
import LsHomeFooter from '../../components/ls-home/ls-home-footer/ls-home-footer.js';
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import unaResidenceLogo from '../../assets/home/una-poliklinika-logo.svg';
import unaResidenceLogo2 from '../../assets/home/una-residence-logo.svg';
import defaultThumbnailImage from '../../assets/home/una-una-residence-default-thumbnail-image.webp'; 

export default function Contact() {

  /* dropdown state value */
  const [dropdown, setDropdown] = React.useState({isOn: false});

  /* showDropdown state function that renders NavigationDropdownDoctors component base on dropdown state */
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        {isOn: !prevState.isOn}
      )
    })
  }

  function reloadContactPage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }

  // part of code that renders
  return (
    <>

      <Helmet>
        <title>Una Residence Poliklinika, Vojvode Milenka 8 Beograd</title>
        <meta
          name="description"
          content=
            "Una Residence Poliklinika, Laser centar za dermatologiju, estetsku i plastičnu hirurgiju, Cancela Center of Excellence, Vojvode Milenka 8, +381 66 6703018">
        </meta>
        <link rel="canonical" href="https://unaresidence.rs/"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

    {/* small screen contact container */}
    <div className="ss-contact-container">
      
      <HeaderDoctors 
        isActive={dropdown.isOn} // passing dropdown state value to HeaderDoctors component
        showDropdown={showDropdown} // passing showDropdown function to HeaderDoctors component
        reloadContactPage={reloadContactPage} // passing reloadContactPage function to HeaderDoctors component
      />

      {dropdown.isOn &&
      <NavigationDropdownDoctors 
        reloadContactPage={reloadContactPage} // passing reloadContactPage to NavigationDropdownDoctors component
      />
      }

      {!dropdown.isOn &&
      <>
        {/* small screen contact logo & info & map container */}
        <div className="ss-contact-logo-info-map-container">
            
          {/* small screen contact logo container */}
          <div className="ss-contact-logo-container">
            <Link to='/'>
            <img 
              src={unaResidenceLogo}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt='Una Residence Poliklinika'/>
            </Link>
          </div>

          {/* small screen contact info container */}
          <div className="ss-contact-info-container">
            <p>
            <a 
              href="https://maps.app.goo.gl/aF2APFvhr41o7Kg78"     
              target="_blank">
              VOJVODE MILENKA 8, SAVSKI VENAC<br/>
              KNEZA MILOŠA RESIDENCE
            </a>
            <br/><br/>
            <a href="tel:+3810117703018">
              +381 011 770-30-18
            </a><br/>
            <a href="tel:+381666703018">
              +381 66 670-30-18
            </a>
            <br/><br/>
            <a href="mailto:poliklinika@unaresidence.rs">
              POLIKLINIKA@UNARESIDENCE.RS
            </a>
            </p>
          </div>

          {/* small screen contact map container */}
          <div className="ss-contact-map-container">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1415.431904417264!2d20.4564818!3d44.8039641!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a71e9b6da3cc3%3A0x6b543580976de891!2sUna%20Residence%20Poliklinika!5e0!3m2!1sen!2srs!4v1703772436799!5m2!1sen!2srs">
            </iframe>
          </div>  
        </div>

        <Part2 />

        <FooterHome 
          backgroundColor='white'/>

        <ArrowUpIcon />
      </>
      }
    </div>
    









    {/* large screen contact container */}
    <div className="ls-contact-container">

      <LsHomeHeader reloadContactPage={reloadContactPage}/>

      {/* large screen contact title container */}
      <div className="ls-contact-title-container">
        <p>Kontakt</p>
      </div>

      {/* large screen contact logo & info & map container */}
      <div className="ls-contact-logo-info-map-container">

        {/* large screen contact logo & info container */}
        <div className="ls-contact-logo-info-container">
          
          <Link to='/'>
          <img 
            src={unaResidenceLogo2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-residence-poliklinika"/>
          </Link>

          <p>
            <a 
              href="https://maps.app.goo.gl/aF2APFvhr41o7Kg78"     
              target="_blank">
              VOJVODE MILENKA 8, SAVSKI VENAC<br/>
              KNEZA MILOŠA RESIDENCE
            </a>
            <br/><br/>
            <a href="tel:+3810117703018">
              +381 011 770-30-18
            </a><br/>
            <a href="tel:+381666703018">
              +381 66 670-30-18
            </a>
            <br/><br/>
            <a href="mailto:poliklinika@unaresidence.rs">
              POLIKLINIKA@UNARESIDENCE.RS
            </a>
            </p>

        </div>

        {/* large screen contact map container */}
        <div className="ls-contact-map-container">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1415.431904417264!2d20.4564818!3d44.8039641!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a71e9b6da3cc3%3A0x6b543580976de891!2sUna%20Residence%20Poliklinika!5e0!3m2!1sen!2srs!4v1703772436799!5m2!1sen!2srs">
          </iframe>
        </div>

      </div>

      <LsHomePart2 />

      <LsHomeFooter backgroundColor='white'/>

      <ArrowUpIcon />
    </div>
  </>
  )
}