import React from "react";
import './question-part-5.css'
import iconArrowDown from '../../../assets/home/index-part5-icon-arrow-down.svg';
import iconArrowUp from '../../../assets/home/index-part5-icon-arrow-up.svg';

export default function QuestionPart5(props) {

  /* part of code that renders */
  return (
    <div>

      {/* small screen queastion part 5 container */}
      <div className="ss-question-part5-container">
        
        {/* small screen question part 5 title */}
        <div 
          onClick={props.onClick} /* passing handleQuestionClick function */
          className="ss-question-part5-title">

            {/* small screen question part 5 text */}
            <p>{props.title}</p>

            {/* small screen question part 5 icon */}
            <div className="ss-question-part5-icon"> 
              <img
                onContextMenu={(e) => e.preventDefault()} 
                src={props.isActive ? iconArrowUp : iconArrowDown}
                alt="una-residence-poliklinika-arrow" />
            </div>
        </div>

         {props.isActive && /* passing isActive prop to declare which paragraph to display */
         <div className="ss-question-part5-paragraph">
            <p>
              {props.paragraphs}
            </p>
        </div>}

      </div>

    </div>
  )
}