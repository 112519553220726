import React from "react";
import './ls-home-footer.css';
import { Link } from "react-router-dom";

import unaResidenceLogo from '../../../assets/home/una-residence-logo.svg';
import candelaLogo from '../../../assets/home/candela-logo.svg';
import facebookIcon from '../../../assets/home/home-navigation-dropdown-icon-facebook.svg';
import instagramIcon from '../../../assets/home/home-navigation-dropdown-icon-instagram.svg';
import youtubeIcon from '../../../assets/home/home-navigation-dropdown-icon-youtube.svg';
import linkedinIcon from '../../../assets/home/home-navigation-dropdown-icon-linkedin.svg';
import googleIcon from '../../../assets/home/home-navigation-dropdown-icon-google.svg';

import LsHomeFooterSocialMediaIcon from "../ls-home-footer-social-media/ls-home-footer-social-media-icon.js";

export default function LsHomeFooter(props) {
  // part of code that renders
  return (
    <>
      {/* large screen home footer container */}
      <div className="ls-home-footer-container" style={{backgroundColor: props.backgroundColor}}>

        {/* large screen home footer container container */}
        <div className="ls-home-footer-container-container">

          {/* large screen home footer text container */}
          <div className="ls-home-footer-text-container">
            <p>
              <Link to='/' onClick={props.reloadHomePage} style={{textDecoration: 'none'}}>
                UNA RESIDENCE POLIKLINIKA
              </Link><br/>
              CANDELA CENTER OF EXCELLENCE<br/><br/>
              <a 
                href="https://maps.app.goo.gl/aF2APFvhr41o7Kg78"     
                target="_blank">
                  VOJVODE MILENKA 8, SAVSKI VENAC<br/>
                  KNEZA MILOŠA RESIDENCE
              </a>
            </p>

            <p>
              <a href="tel:+3810117703018">
                +381 011 770-30-18
              </a><br/>
              <a href="tel:+381666703018">
                +381 66 670-30-18
              </a><br/><br/>
              <a href="mailto:poliklinika@unaresidence.rs">
                POLIKLINIKA@UNARESIDENCE.RS
              </a>
            </p>
          </div>

          {/* large screen home footer logo & icons container */}
          <div className="ls-home-footer-logo-icons-container">

            {/* large screen home footer logo 1 container */}
            <div className="ls-home-footer-logo-1-container">
              <img 
                src={unaResidenceLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt='Una Residence Poliklinika'
                onClick={props.reloadHomePage}/>
            </div>

            {/* large screen home footer logo 2 container */}
            <div className="ls-home-footer-logo-2-container">
              <img 
                src={candelaLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt='Una Residence Poliklinika'
                onClick={props.reloadHomePage}/>
            </div>

            {/* large screen home footer icons container */}
            <div className="ls-home-footer-icons-container">
            <LsHomeFooterSocialMediaIcon 
                width={2.5}
                image={facebookIcon}
                alt='una-residence-poliklinika-facebook-ikonica'
                url='https://www.facebook.com/unaresidence.rs'
              />

              <LsHomeFooterSocialMediaIcon 
                width={2.5}
                image={instagramIcon}
                alt='una-residence-poliklinika-instagram-ikonica'
                url='https://www.instagram.com/unaresidence.rs/'
              />

              <LsHomeFooterSocialMediaIcon 
                width={2.5}
                image={youtubeIcon}
                alt='una-residence-poliklinika-youtube-ikonica'
                url='https://www.youtube.com/@unaresidence'
              />

              <LsHomeFooterSocialMediaIcon 
                width={2.5}
                image={linkedinIcon}
                alt='una-residence-poliklinika-linkedin-ikonica'
                url='https://www.linkedin.com/company/unaresidence'
              />

              <LsHomeFooterSocialMediaIcon 
                width={2.5}
                image={googleIcon}
                alt='una-residence-poliklinika-google-ikonica'
                url='https://www.google.com/maps/place/Una+Residence+Poliklinika/@44.8043021,20.4565232,15z/data=!4m6!3m5!1s0x475a71e9b6da3cc3:0x6b543580976de891!8m2!3d44.8043021!4d20.4565232!16s%2Fg%2F11vb19xw01?entry=ttu'
              />
            </div>
          </div>     
        </div>

        {/* large screen home footer copyright container */}
        <div className="ls-home-footer-copyright-container">
          <p>
            <a href="https://oratorri.com">
              <strong>Oratorri Comunicazioni</strong>
            </a>&nbsp;&nbsp;&nbsp;
            Copyright © 2024 Una Residence&nbsp;&nbsp;&nbsp;
            Sva prava zadržana.
          </p>
        </div>    
      </div>
    </>
  )
}