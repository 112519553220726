import React from "react";
import './ls-home-part-1.css';
import { Link } from "react-router-dom";

import LsHomeHeader from "../ls-home-header/ls-home-header";

import candelaLogo from '../../../assets/home/candela-logo.svg';
import image1 from '../../../assets/home/ls-home-part-1-image.webp';


export default function LsHomePart1(props) {
  // part fo code that renders
  return (
    <>
      {/* large screen home part 1 container */}
      <div className="ls-home-part-1-container">
        
        <LsHomeHeader 
          reloadHomePage={props.reloadHomePage} // passing RealogHomePage function to LsHomeHeader component
        />

        {/* large screen home part 1 container container */}
        <div className="ls-home-part-1-container-container">
          
          {/* large screen home part 1 text & logo & button container */}
          <div className="ls-home-part-1-text-logo-button-container">

            {/* large screen home part 1 text & logo container */}
            <div className="ls-home-part-1-text-logo-container">

              {/* large screen home part 1 text */}
              <p className="ls-home-part-1-text">
                Edukativni laser centar<br />
                za dermatologiju, estetsku<br/>
                i plastičnu hirurgiju
              </p>

              {/* large screen home part 1 logo */}
              <img 
                src={candelaLogo}
                className="ls-home-part-1-logo"
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-residence-poliklinika-naslovna-slika"/>
            </div>

            {/* large screen home part 1 button */}
            <Link to='/contact' style={{textDecoration: 'none'}}>
              <div className="ls-home-part-1-button">
                <p>ZAKAŽITE PREGLED</p>
              </div>
            </Link>

          </div>

          {/* large screen home part 1 image container */}
          <div className="ls-home-part-1-image-container">

            {/* large screen home part 1 image */}
            <img 
              src={image1}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-residence-poliklinika-naslovna-slika"/>

            {/* large screen home part 1 decoration 1 */}
            <div className="ls-home-part-1-decoration-1"></div>

            {/* large screen home part 1 decoration 2 */}
            <div className="ls-home-part-1-decoration-2"></div>

          </div>
        </div>
      </div>

      {/* large screen home part 1 after margin container */}
      <div className="ls-home-part-1-margin-container">

      </div>
    </>
  )
}