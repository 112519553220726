import React from "react";
import './home.css';
import { Helmet } from "react-helmet";

import HeaderDoctors from "../../components/doctors/header-doctors/header-doctors.js";
import NavigationDropdownDoctors from "../../components/doctors/navigation-dropdown-doctors/navigation-dropdown-doctors.js";
import Part1 from "../../components/home/part-1/part-1.js";
import Part2 from "../../components/home/part-2/part-2.js";
import Part3 from "../../components/home/part-3/part-3.js";
import Part4 from "../../components/home/part-4/part-4.js";
import Part5 from "../../components/home/part-5/part-5.js";
import FooterHome from "../../components/home/footer-home/footer-home.js";

import LsHomePart1 from "../../components/ls-home/ls-home-part-1/ls-home-part-1.js";
import LsHomePart2 from "../../components/ls-home/ls-home-part-2/ls-home-part-2.js";
import LsHomePart3 from "../../components/ls-home/ls-home-part-3/ls-home-part-3.js";
import LsHomePart4 from "../../components/ls-home/ls-home-part-4/ls-home-part-4.js";
import LsHomeQuestion from "../../components/ls-home/ls-home-questions/ls-home-questions.js";
import LsHomeNewsletter from "../../components/ls-home/ls-home-newsletter/ls-home-newsletter.js";
import LsHomeFooter from "../../components/ls-home/ls-home-footer/ls-home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import defaultThumbnailImage from '../../assets/home/una-una-residence-default-thumbnail-image.webp';

export default function Home() {

  /* dropdown state value */
  const [dropdown, setDropdown] = React.useState({isOn: false});

  /* showDropdown state function that renders NavigationDropdownDoctors component base on dropdown state */
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        {isOn: !prevState.isOn}
      )
    })
  }

  function reloadHomePage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }

  /* part of code that renders */
  return (
    <>

      <Helmet>
        <title>Una Residence Poliklinika, Vojvode Milenka 8 Beograd</title>
        <meta
          name="description"
          content=
            "Una Residence Poliklinika, Laser centar za dermatologiju, estetsku i plastičnu hirurgiju, Cancela Center of Excellence, Vojvode Milenka 8, +381 66 6703018">
        </meta>
        <link rel="canonical" href="https://unaresidence.rs/"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      {/* small screen home container */}
      <div className="ss-home-container">
        <HeaderDoctors 
          isActive={dropdown.isOn} // passing dropdown state value to HeaderDoctors component
          showDropdown={showDropdown} // passing showDropdown function to HeaderDoctors component
          reloadHomePage={reloadHomePage} // passing reloadHomePage function to HeaderDoctors component
        />

        {dropdown.isOn &&
        <NavigationDropdownDoctors 
          reloadHomePage={reloadHomePage} // passing reloadHomePage function to NavigationDropdownDoctors component
        />
        }

        {!dropdown.isOn && /* rendering rest of components base on dropdown state value */
        <div>
          <Part1 />
          <Part2 />
          <Part3 background='white'/>
          <Part4 />
          <Part5 reloadHomePage={reloadHomePage}/>
          <FooterHome reloadHomePage={reloadHomePage}/>
          <ArrowUpIcon />
        </div>
        }
      </div>










      {/* large screen home container */}
      <div className="ls-home-container">

        <LsHomePart1 
          reloadHomePage={reloadHomePage} // passing ReloadHomePageFunction trough LsHomePart1 component to LsHomeHeader component
        />

        <LsHomePart2 
          reloadHomePage={reloadHomePage} // passing ReloadHomePageFunction to LsHomePart2 component
        />

        <LsHomePart3 />

        <LsHomePart4 />

        <LsHomeQuestion />

        <LsHomeNewsletter 
          reloadHomePage={reloadHomePage} // passing ReloadHomePageFunction to LsHomeNewsletter component
        />

        <LsHomeFooter 
          reloadHomePage={reloadHomePage} // passing ReloadHomePageFunction to LsHomeFooter component
        />

        <ArrowUpIcon />

      </div>


    </>
  )
}