import React from "react";
import './employe-make-reservation.css';

import phoneIcon from '../../../assets/employe/una-residence-poliklinika-employe-phone-icon.svg';

export default function EmployeMakeReservation (props) {

  /* part of code that renders */
  return (

    /* small screen employe make reservation container */
    <div 
      className="ss-employe-make-reservation-container"
      style={{marginTop: props.marginTop + 'vh'}}>
      
      {/* small screen employe make reservation phone container */}
      <a href="tel:+381666703018">
        <div 
          className="ss-employe-make-reservation-phone-container">
          <img 
            src={phoneIcon}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-residence-poliklinika-phone-icon"/>
        </div>
      </a>

      {/* small screen employe make reservation button container */}
      <a href="tel:+381666703018">
        <div className="ss-employe-make-reservation-button-container">
          <p>zakažite pregled</p>
        </div>
      </a>

    </div>
  
  )
}