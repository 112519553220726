import React from "react";
import './post-doctor-card.css';
import { Link } from "react-router-dom";

export default function PostDoctorCard({card}) {
  // part of code that renders
  return (
    <>
      <Link to={card.link} style={{textDecoration: 'none'}}>
        {/* large screen post doctor card container */}
        <div className="ls-post-doctor-card-container">
          
          {/* large screen post doctor card image container */}
          <div className="ls-post-doctor-card-image-container">
            <img 
              src={card.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={card.alternative}/>
          </div>

          {/* large screen post doctor card text container */}
          <div className="ls-post-doctor-card-text-container">
            
            {/* large screen post doctor card name */}
            <p className="ls-post-doctor-card-name">
              {card.name}<br />{card.surname}
            </p>

            {/* large screen post doctor card description */}
            <p className="ls-post-doctor-card-description">
              {card.description}
            </p>
          </div>
        </div>










        {/* small screen post doctor card container */}
        <div className="ss-post-doctor-card-container">

          {/* small screen post doctor card image container */}
          <div className="ss-post-doctor-card-image-container">
            <img 
              src={card.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={card.alternative}/>
          </div>

           {/* large screen post doctor card text container */}
           <div className="ls-post-doctor-card-text-container">

              {/* small screen post doctor card name */}
              <p className="ss-post-doctor-card-name">
                {card.name}<br />{card.surname}
              </p>

              {/* small screen post doctor card description */}
              <p className="ss-post-doctor-card-description">
                {card.description}
              </p>
           </div>
        </div>
      </Link>
    </>
  )
}