import React from "react";
import './ls-blog-field-card.css';

import cardArrowLeft from '../../../assets/blog/una-residence-poliklinika-blog-card-arrow-left.svg';
import cardArrowDown from '../../../assets/blog/una-residence-poliklinika-blog-card-arrow-down.svg';

export default function LsBlogFieldCard({card, showCardContent, isOn, borderStyle, paddingStyle}) {

  // part of code that renders
  return (
    <>
      {/* large screen blog field card container */}
      <div className="ls-blog-field-card-container">

        {/* large screen blog field card button container */}
        <div 
          className="ls-blog-field-card-button-container"
          // receiving showCardContent function from blog.js that chaings isOn value in fieldCardsState that determines which cards's content will be visible.
          onClick={showCardContent}>

          {/* large screen blog field card button container container */}
          <div 
            className={isOn ? 
              'ls-blog-field-card-button-active-container-container' :
              'ls-blog-field-card-button-inactive-container-container'}>
            <p>{card.name.charAt(0).toUpperCase()+card.name.slice(1)}</p>
          </div>

          {/* large screen blog field card button arrow container */}
          <div className="ls-blog-field-card-button-arrow-container">
            <img
              // receiving isOn vale from blog.js fieldCardsState that determines which image will be displayed 
              src={isOn ? cardArrowDown : cardArrowLeft} 
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-strelica"/>
          </div>
        </div>
        
        {/* large screen blog field card content container */}
        <div 
          className="ls-blog-field-card-content-container"
          // receiving isOn value from blog.js fieldCardsState that determines if card content will be visible
          style={{
            display: isOn ? 'flex' : 'none', 
            borderBottom: borderStyle,
            marginBottom: isOn? '1.667vw' : '',
            paddingBottom: paddingStyle
          }}>
          {card.content.map((paragraph) => {
            return (
              <p 
                key={paragraph.id}
                className="ls-blog-field-card-content-paragraph">
                {paragraph.text.toUpperCase()}
              </p>
            )
          })}
        </div>

      </div>
    </>
  )
}