import React from "react";
import './icons-footer.css'

export default function IconsFooter(props) {

  /* part of code that renders */
  return (
    <div>

      {/* small screen icons footer container */}
      <a href={props.url} target="_blank">
      <div className="ss-icons-footer-container">
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={props.image}
          alt={props.alt} />
      </div>
      </a>

    </div>
  )
}