import React from "react";
import './services-field.css';

import candelaLogo from '../../../assets/home/candela-logo.svg';

export default function ServicesField({field}) {
  // part of code that renders
  return (
    <>  
      {/* small screen services field container */}
      <div className="ss-services-field-container">
        
        {/* small screen services field title container */}
        <div className="ss-services-field-title-container">
          <p>{field.title.charAt(0).toUpperCase() + field.title.slice(1)}</p>
        </div>

        {field.id === 2 && 
        <img 
          src={candelaLogo}
          onContextMenu={(e) => {e.preventDefault(e)}}
          alt="una-residence-poliklinika-candela-logo"
          className="ss-services-field-image"/>
        }

        {/* small screen services field text container */}
        <div className="ss-services-field-text-container">
          {field.content.map((listItem) => {
            if (field.id === 2) { // if object id is 2 render strong
              if (listItem.id === 7) { // if object id in field is 7 render horizontal rule
                return (
                  // small screen field text horizontal rule
                  <hr key={listItem.id} className="ss-services-field-text-horizontal-rule"/>
                )
              } else {
                return (
                  <p key={listItem.id}>
                    <strong>{listItem.text.charAt(0).toUpperCase() + listItem.text.slice(1)}</strong>
                  </p>
                )
              }
            } else {
              return (
                <p key={listItem.id}>
                  {listItem.text.charAt(0).toUpperCase() + listItem.text.slice(1)}
                </p>
              )
            }
          })}
        </div>       
      </div>
    </>
  )
}