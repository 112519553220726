import React from "react";
import './services.css';
import { Helmet } from "react-helmet";

import HeaderDoctors from "../../components/doctors/header-doctors/header-doctors.js";
import NavigationDropdownDoctors from "../../components/doctors/navigation-dropdown-doctors/navigation-dropdown-doctors.js";
import NewsletterDoctors from "../../components/doctors/newsletter-doctors/newsletter-doctors.js";
import ServicesField from "../../components/services/services-field/services-field.js";
import FooterHome from "../../components/home/footer-home/footer-home.js";

import LsHomeHeader from  '../../components/ls-home/ls-home-header/ls-home-header.js';
import LsHomePart3 from "../../components/ls-home/ls-home-part-3/ls-home-part-3.js";
import LsHomeNewsletter from "../../components/ls-home/ls-home-newsletter/ls-home-newsletter.js";
import LsHomeFooter from "../../components/ls-home/ls-home-footer/ls-home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import lsHomeServicesFieldData from "../../assets/data/ls-home/ls-home-services-field-data.js";

import defaultThumbnailImage from '../../assets/home/una-una-residence-default-thumbnail-image.webp';

export default function Services() {

  /* dropdown state value that changes by showDropdown function */
  const [dropdown, setDropdown] = React.useState(
    {
      isOn: false
    }
  )
  
  /* function changes value of dropdown state and is passed to HeaderDoctors component */
  function showDropdown() {
    setDropdown((prevState) => {
      return(
        {isOn: !prevState.isOn}
      )
    })
  }

  function reloadServicesPage() {
    window.location.reload();
  }

  /* part of code that renders */
  return (
    <>

      <Helmet>
        <title>Una Residence Poliklinika, Vojvode Milenka 8 Beograd</title>
        <meta
          name="description"
          content=
            "Una Residence Poliklinika, Laser centar za dermatologiju, estetsku i plastičnu hirurgiju, Cancela Center of Excellence, Vojvode Milenka 8, +381 66 6703018">
        </meta>
        <link rel="canonical" href="https://unaresidence.rs/"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      
      {/* small screen services container */}
      <div 
        className="ss-services-container" 
        /* change background and height (to be fullscreen) depending on dropdown state value */
        style={dropdown.isOn ? {backgroundColor:'white', height:'100vh'} : {backgroundColor:'#F6F6F6'}}>

        <HeaderDoctors 
          showDropdown={showDropdown} /* passing showDropdown function to HeaderDoctors component to be used on click */
          isActive={dropdown.isOn}/> {/* passing dropdown state value to HeaderDoctors changing header icon that depends on that value */}
        
        {/* render part of code depend of dropdown state value */}
        {dropdown.isOn ? <NavigationDropdownDoctors reloadServicesPage={reloadServicesPage}/> :

        
        /* small screen services container  container */
        <div className="ss-services-container-container">
          {/* small screen services button container */}
          <div 
            className="ss-services-button-container"
            onClick={reloadServicesPage}>
            <p>Usluge</p>
          </div>

          {lsHomeServicesFieldData.map((field) => {
            if (field.id === 2) { // skip object with id 2 and render it last
              return false;
            } else {
              return (
                <ServicesField 
                  key={field.id}
                  field={field}/>
              )
            }
          })}

          {lsHomeServicesFieldData.map((field) => {
            if (field.id === 2) { // render only object with id 2
              return (
                <ServicesField 
                  key={field.id}
                  field={field}/>
              )
            }
          })}

          <NewsletterDoctors />

          <FooterHome /> 

          <ArrowUpIcon />
        </div>
        }
      </div>










      {/* large screen services container */}
      <div className="ls-services-container">

        <LsHomeHeader reloadServicesPage={reloadServicesPage}/>
        
        {/* large screen services background 1 */}
        <div className="ls-services-background-1"></div>

        <LsHomePart3 background='#f6f6f6' reloadServicesPage={reloadServicesPage}/>

        {/* large screen services background 2 */}
        <div className="ls-services-background-2"></div>

        <LsHomeNewsletter />

        <LsHomeFooter />

        <ArrowUpIcon />
      </div>

    </>
  )
}