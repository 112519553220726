import React from "react";
import './ls-home-part-4.css';
import { Link } from "react-router-dom";

import lsHomeTeamData from "../../../assets/data/ls-home/ls-home-team-data.js";

import LsHomePart4Employe from "../ls-home-part-4-employe/ls-home-part-4-employe.js";

export default function LsHomePart4(props) {

  // this function devide parameter array into more arrays and every of those arrays contain some number of objects which is defined with chunkSize.
  function devideArray(array, chunkSize) {
    const chunks = [];
    let id = 1; // giving id to every group of objects (row) so we can map over them.
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push({
        id: id++,
        data: array.slice(i, i + chunkSize)
      })
    }
    return chunks;
  }

  // this const contains arrays with 3 objects devided from fieldFilter array.
  const lsHomeTeamDataDevided = devideArray(lsHomeTeamData, 3);

  // part of code that render
  return (
    <>
      {/* large screen home part 4 container */}
      <div className="ls-home-part-4-cotainer">

        {/* large screen home part 4 team button */}
        <Link to='/doctors' style={{textDecoration:'none'}} onClick={props.reloadDoctorPage}>
          <div className="ls-home-part-4-team-button">
            <p>Naš tim</p>
          </div>
        </Link>

        {/* large screen home part 4 team container */}
        <div className="ls-home-part-4-team-container">
          {lsHomeTeamDataDevided.map((row) => {
            return (
              <div
                className="ls-home-part-4-team-row-container"
                key={row.id}>
                  {row.data.map((employe) => {
                    return (
                      <LsHomePart4Employe 
                        key={employe.id}
                        employe={employe}/>
                    )
                  })}
              </div>
            )
          })}
        </div>

      </div>
    </>
  )
}