import React from "react";
import './ls-home-part-3-field.css';

import candelaLogo from '../../../assets/home/candela-logo.svg';

export default function LsHomePart3Field({textGroup}) {
  // part of code that renders
  return (
    <>
      {/* large screen home part 3 field container */}
      <div className="ls-home-part-3-field-container">

        {/* large screen home part 3 title container */}
        <div className="ls-home-part-3-title-container">
          <p>{textGroup.title.charAt(0).toUpperCase()+textGroup.title.slice(1)}</p>
        </div>

        {textGroup.id === 2 &&
          <img 
            src={candelaLogo}
            className="ls-home-part-3-image"
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-residence-poliklinika-candela-logo"/>
        } 

        {/* large screen home part 3 content container */}
        <ul className="ls-home-part-3-content-container">
          {textGroup.content.map((listItem) => {
            if (textGroup.id === 2) {
              if (listItem.id === 7) {
                return (
                  <div 
                    className="ls-home-part-3-content-horizontal-rule" 
                    key={listItem.id}>
                  </div>
                )
              } else {
                return (
                  <li 
                    key={listItem.id}
                    className="ls-home-part-3-content-bold">
                      {listItem.text.charAt(0).toUpperCase()+listItem.text.slice(1)}
                  </li>
                )
              }
            } else {
              return (
                <li key={listItem.id}>
                  {listItem.text.charAt(0).toUpperCase()+listItem.text.slice(1)}
                </li>
              )
            }
          })}
        </ul>  
      </div>
    </>
  )
}