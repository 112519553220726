import React from "react";
import { Link } from "react-router-dom";
import './header-doctors.css'

import unaResidencePoliklinikaLogo from '../../../assets/home/una-poliklinika-logo.svg';
import navigationIcon1 from '../../../assets/home/home-navigation-icon-1.svg';
import navigationIcon2 from '../../../assets/home/home-navigation-icon-2.svg';

export default function HeaderDoctors(props) {

  /* part of code that renders */
  return (
    <div>
      
      {/* small screen doctors header container */}
      <div className="ss-doctors-header-container">

      {/* small screen doctors header container container */}
        <div className="ss-doctors-header-container-container">

          {/* small screen doctors header container logo */}
          <Link to='/'>
          <div className="ss-doctors-header-container-logo">
            <img 
              src={unaResidencePoliklinikaLogo}
              onContextMenu={(e) => {e.preventDefault()}}
              alt="Una Residence Poliklinika"/>
          </div>
          </Link>
          

          {/* small screen doctors header container navigation icon */}
          <div 
            className="ss-doctors-header-container-navigation-icon"
            onClick={props.showDropdown}> {/* passing function from Doctors page that changes the value of state in Doctors page */}
            <img 
              src={props.isActive ? navigationIcon2 : navigationIcon1}
              onContextMenu={(e) => {e.preventDefault()}}
              alt="una-residence-poliklinika-navigation-icon"/>
          </div>

        </div>

      </div>

    </div>
  )
}