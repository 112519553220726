import React from "react";
import './doctors.css';
import { Helmet } from "react-helmet";

import HeaderDoctors from "../../components/doctors/header-doctors/header-doctors.js";
import DoctorDoctors from "../../components/doctors/doctor-doctors/doctor-doctors.js";
import NavigationDropdownDoctors from "../../components/doctors/navigation-dropdown-doctors/navigation-dropdown-doctors.js";
import NewsletterDoctors from "../../components/doctors/newsletter-doctors/newsletter-doctors.js";
import FooterHome from "../../components/home/footer-home/footer-home.js";

import LsHomeHeader from "../../components/ls-home/ls-home-header/ls-home-header.js";
import LsHomePart4 from "../../components/ls-home/ls-home-part-4/ls-home-part-4.js";
import LsHomeNewsletter from "../../components/ls-home/ls-home-newsletter/ls-home-newsletter.js";
import LsHomeFooter from "../../components/ls-home/ls-home-footer/ls-home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import lsHomeTeamData from "../../assets/data/ls-home/ls-home-team-data.js";
import defaultThumbnailImage from '../../assets/home/una-una-residence-default-thumbnail-image.webp';

export default function Doctors() {

  /* dropdown state value that changes by showDropdown function */
  const [dropdown, setDropdown] = React.useState(
    {
      isOn: false
    }
  )
  
  /* function changes value of dropdown state and is passed to HeaderDoctors component */
  function showDropdown() {
    setDropdown((prevState) => {
      return(
        {isOn: !prevState.isOn}
      )
    })
  }

  function reloadDoctorPage() {
    window.location.reload();
  }

  /* part of code that renders */
  return (
    <>

      <Helmet>
        <title>Una Residence Poliklinika, Vojvode Milenka 8 Beograd</title>
        <meta
          name="description"
          content=
            "Una Residence Poliklinika, Laser centar za dermatologiju, estetsku i plastičnu hirurgiju, Cancela Center of Excellence, Vojvode Milenka 8, +381 66 6703018">
        </meta>
        <link rel="canonical" href="https://unaresidence.rs/"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet> 
      
      {/* small screen doctors container */}
      <div 
        className="ss-doctors-container" 
        /* change background and height (to be fullscreen) depending on dropdown state value */
        style={dropdown.isOn ? {backgroundColor:'white', height:'100vh'} : {backgroundColor:'#F6F6F6'}}>

        <HeaderDoctors 
          showDropdown={showDropdown} /* passing showDropdown function to HeaderDoctors component to be used on click */
          isActive={dropdown.isOn}/> {/* passing dropdown state value to HeaderDoctors changing header icon that depends on that value */}
        
        {/* render part of code depend of dropdown state value */}
        {dropdown.isOn ? <NavigationDropdownDoctors reloadDoctorPage={reloadDoctorPage}/> :

        /* small screen doctors container  container */
        <div className="ss-doctors-container-container">
          {/* small screen doctors button container */}
          <div 
            className="ss-doctors-button-container"
            onClick={reloadDoctorPage}>
            <p>Doktori</p>
          </div>

          {lsHomeTeamData.map((doctor) => {
            return (
              <DoctorDoctors 
                key={doctor.id}
                doctor={doctor}
              />
            )
          })}

          <NewsletterDoctors />

          <FooterHome /> 

          <ArrowUpIcon />
        </div>
        }
      </div>
      









      {/* large screen doctors container */}
      <div className="ls-doctors-container">

        <LsHomeHeader reloadDoctorPage={reloadDoctorPage}/>
        
        {/* large screen doctors background 1 */}
        <div className="ls-doctors-background-1"></div>

        <LsHomePart4 background='#f6f6f6' reloadDoctorPage={reloadDoctorPage}/>

        {/* large screen doctors background 2 */}
        <div className="ls-doctors-background-2"></div>

        <LsHomeNewsletter />

        <LsHomeFooter />

        <ArrowUpIcon />
      </div>
    </>
  )
}