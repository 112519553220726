// list of field cards
const fieldCardsData = [
  {
    id: 1,
    name: 'Dermatologija',
    content: [
      {
        id: 1,
        text: 'Dermatološki pregled'
      },
      {
        id: 2,
        text: 'Dermoskopija'
      },
      {
        id: 3,
        text: 'Patch-test'
      },
      {
        id: 4,
        text: 'Biopsija kože'
      },
      {
        id: 5,
        text: 'Lečenje hiperhidroze'
      },
      {
        id: 6,
        text: 'Uklanjanje visećih bradavica'
      },
      {
        id: 7,
        text: 'Uklanjanje milija'
      },
      {
        id: 8,
        text: 'Uklanjanje fibroma'
      },
      {
        id: 9,
        text: 'Uklanjanje neurofibroma'
      },
      {
        id: 10,
        text: 'Uklanjanje virusnih bradavica'
      },
      {
        id: 11,
        text: 'Ksentalazme'
      },
      {
        id: 12,
        text: 'Uklanjanje granuloma'
      },
      {
        id: 13,
        text: 'Hiperkeratoza'
      },
      {
        id: 14,
        text: 'Uklanjanje hemangioma'
      },
      {
        id: 15,
        text: 'Uklanjanje benignih lezija'
      }
    ]
  },
  {
    id: 2,
    name: 'Estetska i rekonstruktivna hirurgija',
    content: [
      {
        id: 1,
        text: 'Hirurško uklanjanje lipoma'
      },
      {
        id: 2,
        text: 'Zatezanje kapaka - blefaroplastika'
      },
      {
        id: 3,
        text: 'Lip lift'
      },
      {
        id: 4,
        text: 'Biopolimer usana'
      },
      {
        id: 5,
        text: 'Hirurško uklanjanje masnog jastučeta iz obraza'
      },
      {
        id: 6,
        text: 'Hirurška korekcija ušiju'
      },
      {
        id: 7,
        text: 'Hirurška intervencija lipoma'
      },
      {
        id: 8,
        text: 'Hirurška intervencija ateroma'
      },
      {
        id: 9,
        text: 'Hirurška intervencija epidarmalna cista'
      },
      {
        id: 10,
        text: 'Aptos niti donje trećine lica'
      },
      {
        id: 9,
        text: 'Aptos niti celog lica'
      },
    ]
  },
  {
    id: 3,
    name: 'Laseri',
    content: [
      {
        id: 1,
        text: 'Candela Frax Pro'
      },
      {
        id: 2,
        text: 'Candela Nordlys'
      },
      {
        id: 3,
        text: 'Candela CO2RE'
      },
      {
        id: 4,
        text: 'Candela Profound Matrix Pro'
      },
      {
        id: 5,
        text: 'Candela Gentle Lase Pro'
      },
      {
        id: 6,
        text: 'Candela PicoWay'
      },
      {
        id: 7,
        text: 'Endolift'
      },
      {
        id: 8,
        text: 'Zimmer Z lipo'
      },
      {
        id: 9,
        text: 'Zimmer Z tone'
      },
      {
        id: 10,
        text: 'Zimmer Z Wave'
      }
    ]
  },
  {
    id: 4,
    name: 'Anti-aging',
    content: [
      {
        id: 1,
        text: 'AESPLLA stimulator kolagena tretman'
      },
      {
        id: 2,
        text: 'Nythia injekcioni kolagen tretman'
      },
      {
        id: 3,
        text: 'Podmlađivanje i zatezanje lica - tretman sa faktorima rasta'
      },
      {
        id: 1,
        text: 'Profhilo tretman'
      },
      {
        id: 2,
        text: 'Bioregeneracija (polinukleotidi)'
      },
      {
        id: 3,
        text: 'Mezoterapija sa faktorima rasta'
      },
      {
        id: 1,
        text: 'PRP tretman lica, vrata i dekoltea'
      },
      {
        id: 2,
        text: 'PRP tretman kose'
      },
      {
        id: 3,
        text: 'CELLULAR matrix tretman lica, vrata i dekoltea'
      },
      {
        id: 1,
        text: 'Biorevitalizacija'
      },
      {
        id: 2,
        text: 'Hemijski piling za normalnu, suvu, masnu i mešovitu kožu'
      },
      {
        id: 3,
        text: 'Hidratacija lica, vrata i dekoltea hijaluronskom kiselinom'
      },
      {
        id: 1,
        text: 'Dermapen'
      },
      {
        id: 2,
        text: 'Personalizovani tretman lica po stanju i tipu kože'
      },
      {
        id: 3,
        text: 'DermaFacial tretman'
      }
    ]
  },
  {
    id: 5,
    name: 'Laserska epilacija',
    content: [
      {
        id: 1,
        text: 'Laserska epilacija celog tela'
      },
      {
        id: 2,
        text: 'Laserska epilacija celog lica'
      },
      {
        id: 3,
        text: 'Laserska epilacija pojedinačnih regija'
      },
      {
        id: 4,
        text: 'Laserska epilacija intimne regije'
      },
      {
        id: 5,
        text: 'Laserska epilacija ruku'
      },
      {
        id: 6,
        text: 'Laserska epilacija nogu'
      }
    ]
  },
  {
    id: 6,
    name: 'Laser tretmani',
    content: [
      {
        id: 1,
        text: 'Lasersko uklanjanje tetovaže na obrvama - Picoway laser'
      },
      {
        id: 2,
        text: 'Lasersko uklanjanje trajne šminke - Picoway laser'
      },
      {
        id: 3,
        text: 'Fotorejuvenacija - Nordlys laser'
      },
      {
        id: 4,
        text: 'Lasersko uklanjanje lezija (akne, kapilari na licu, rozacea, pigmentne strije i ožiljci) - Nordlys laser'
      },
      {
        id: 5,
        text: 'Endolift - Alternativa hirurškom face liftingu'
      },
      {
        id: 6,
        text: 'Laserska epilacija - Picoway laser'
      },
      {
        id: 7,
        text: 'Lifting lica - CO2RE frakcioni laser'
      },
      {
        id: 8,
        text: 'Tretmani - Profound Matrix laser'
      }
    ]
  },
  {
    id: 7,
    name: 'Saveti',
    content: [
      {
        id: 1,
        text: 'Priprema za dermatološki pregled'
      },
      {
        id: 2,
        text: 'Kada je pravo vreme za botoks'
      },
      {
        id: 3,
        text: 'Aptos niti'
      },
      {
        id: 4,
        text: 'Operacija kapaka - Saveti'
      },
    ]
  },
  {
    id: 8,
    name: 'Razno',
    content: [
      {
        id: 1,
        text: 'Pre i posle'
      },
      {
        id: 2,
        text: 'Promocije'
      },
    ]
  },
]

export default fieldCardsData;