import React from "react";
import './newsletter-part-5.css';

import unaResidenceLogo2 from '../../../assets/home/una-residence-logo-2.svg';

export default function NewsletterPart5(props) {
  // part of code that renders
  return (
    <>
      {/* small screen part 5 container newsletter */}
      <div className="ss-part5-container-newsletter">

        {/* small screen part 5 newsletter container container */}  
        <div className="ss-part5-newsletter-container-container">
          
          {/* small screen part 5 newsletter container logo */}
          <div className="ss-part5-newsletter-container-logo">
            <img 
              src={unaResidenceLogo2}
              onContextMenu={(e) => e.preventDefault()}
              alt="Una Residence Poliklinika"
              onClick={props.reloadHomePage}/>
          </div>
          
          {/* small screen part 5 newsletter container paragraph */}
          <p className="ss-part5-newsletter-container-paragraph">
            Prijavite se za Newsletter, postanite član kluba i ostvarite posebne akcije i pogodnosti u Una Residence Poliklinici
          </p>

          {/* small screen part 5 newsletter container input */}
          <form 
            method="POST"
            action="https://formsubmit.co/poliklinika@unaresidence.rs"
            id="ss-part5-newsletter-form">
            <input 
              className="ss-part5-newsletter-container-input"
              type="email"
              placeholder="vaša Email adresa"
              name="Newsletter"
            />
          </form>

          {/* small screen part 5 newsletter container button */}
          <div className="ss-part5-newsletter-container-button">
            <button 
              type="submit"
              form="ss-part5-newsletter-form"
            >POŠALJI
            </button>
          </div>
          
        </div>
      </div>
    </>
  )
}