const lsHomeServicesFieldData = [
  {
    id: 1,
    title: 'laser tretmani',
    content: [
      {
        id: 1,
        text: '• Lasersko uklanjanje tetovaže na obrvama - Picoway laser - od 18,000.00'
      },
      {
        id: 2,
        text: '• Lasersko uklanjanje trajne šminke - Picoway laser - od 18,000.00'
      },
      {
        id: 3,
        text: '• Fotorejuvenacija, Nordlys laser - od 18,000.00'
      },
      {
        id: 4,
        text: '• Lasersko uklanjanje lezija (akne, kapilari na licu, rozacea, pigmentne strije i ožiljci) - Nordlys laser - od 18,000.00'
      },
      {
        id: 5,
        text: '• Endolift - Alternativa hirurškom face liftingu - od 144,000.00'
      },
      {
        id: 6,
        text: '• Laserska epilacija - Picoway laser'
      },
      {
        id: 7,
        text: '• Lifting lica - CO2RE frakcioni laser - od 45,000.00'
      },
      {
        id: 8,
        text: '• Tretmani - Profound Matrix laser - od 80,000.00'
      }
    ]
  },
  {
    id: 2,
    title: 'laseri',
    content: [
      {
        id: 1,
        text: '• Frax Pro'
      },
      {
        id: 2,
        text: '• Nordlys'
      },
      {
        id: 3,
        text: '• CO2RE'
      },
      {
        id: 4,
        text: '• Profound Matrix Pro'
      },
      {
        id: 5,
        text: '• Gentle Lase Pro'
      },
      {
        id: 6,
        text: '• Pico Way'
      },
      {
        id: 7,
        text: 'This object represents horizontal rule (do not change it).'
      },
      {
        id: 8,
        text: '• Endolift laser'
      },
      {
        id: 9,
        text: '• Zimmer Z lipo'
      },
      {
        id: 10,
        text: '• Zimmer Z tone'
      },
      {
        id: 11,
        text: '• Zimmer Z Wave'
      },
    ]
  },
  {
    id: 3,
    title: 'anti-aging tretmani',
    content: [
      {
        id: 1,
        text: '• AESPLLA stimulator kolagena tretman - od 48,000.00'
      },
      {
        id: 2,
        text: '• Nythia injekcioni kolagen tretman - od 30,000.00'
      },
      {
        id: 3,
        text: '• Podmlađivanje i zatezanje lica - tretman sa faktorima rasta - od 30,000.00'
      },
      {
        id: 4,
        text: '• Profhilio tretman - od 30,000.00'
      },
      {
        id: 5,
        text: '• Bioregeneracija (polinukleotidi) - od 30,000.00'
      },
      {
        id: 6,
        text: '• Mezoterapija sa faktorima rasta - od 24,000.00'
      },
      {
        id: 7,
        text: '• PRP tretman lica, vrata i dekoltea - od 24,000.00'
      },
      {
        id: 8,
        text: '• PRP tretman kose - od 24,000.00'
      },
      {
        id: 9,
        text: '• CELLULAR matrix tretman lica, vrata i dekoltea - od 36,000.00'
      },
      {
        id: 10,
        text: '• Biorevitalizacija - od 22,000.00'
      },
      {
        id: 11,
        text: '• Hemijski piling za normalnu, suvu, masnu i mešovitu kožu - od 9,000.00'
      },
      {
        id: 12,
        text: '• Hidratacija lica, vrata i dekoltea hijaluronskom kiselinom - od 32,000.00'
      },
      {
        id: 13,
        text: '• Dermapen - od 18,000.00'
      },
      {
        id: 14,
        text: '• Personalizovani tretman lica po stanju i tipu kože - od 14,000.00'
      },
      {
        id: 15,
        text: '• DermaFacial tretman - od 18,000.00'
      }
    ]
  },
  {
    id: 4,
    title: 'Botox i hijaluron tretmani',
    content: [
      {
        id: 1,
        text: '• Hijaluronski fileri Juvederm - od 30,000.00'
      },
      {
        id: 2,
        text: '• Hijaluronski fileri Revanesse - od 30,000.00'
      },
      {
        id: 3,
        text: '• Nehirurška korekcija nosa - od 36,000.00'
      },
      {
        id: 4,
        text: '• Hijaluronidaza - od 12,000.00'
      },
      {
        id: 5,
        text: '• Botox gornje trećine lica - od 42,000.00'
      },
      {
        id: 6,
        text: '• Botox pojedinačne regije - od 22,000.00'
      },
      {
        id: 7,
        text: '• Lečenje hiperhidroze botoxom'
      }
    ]
  },
  {
    id: 5,
    title: 'Hirurške intervencije',
    content: [
      {
        id: 1,
        text: '• Hirurško uklanjanje lipoma - od 18,000.00'
      },
      {
        id: 2,
        text: '• Zatezanje kapaka - blefaroplastika - od 120,000.00	'
      },
      {
        id: 3,
        text: '• Lip lift - od 100,000.00'
      },
      {
        id: 4,
        text: '• Vađenje biopolimera usana - od 84,000.00'
      },
      {
        id: 5,
        text: '• Lečenje melazma'
      },
      {
        id: 6,
        text: '• Hirurško uklanjanje masnog jastučeta iz obraza - od 100,000.00'
      },
      {
        id: 7,
        text: '• Hirurška korekcija ušiju - od 120,000.00'
      },
      {
        id: 8,
        text: '• Hirurška intervencija lipoma - od 18,000.00'
      },
      {
        id: 9,
        text: '• Hirurška intervencija ateroma - od 18,000.00'
      },
      {
        id: 10,
        text: '• Hirurška intervencija epidarmalna cista - od 12,000.00'
      },
      {
        id: 11,
        text: '• Aptos niti za donju trećinu lica - od 120,000.00'
      },
      {
        id: 12,
        text: '• Aptos niti za kompletno lice - od 240,000.00'
      },
    ]
  }
]

export default lsHomeServicesFieldData;