import React from "react";
import './employe-navigation-one-icon.css';

export default function EmployeNavigationOneIcon(props) {

  /* part of code that renders */
  return (

    /* small screen employe navigation one icon container */
    <div className="ss-employe-navigation-one-icon-container">
      <img 
        src={props.source}
        onContextMenu={(e) => {e.preventDefault(e)}}/>
    </div>

  )
}