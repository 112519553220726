import React from "react";
import { Link } from "react-router-dom";
import './employe-back-arrow.css';

import backArrowIcon from '../../../assets/employe/una-residence-poliklinika-employe-back-arrow-icon.svg';

export default function EmployeBackArrow() {

  /* part of code that renders */
  return(

      /* small screen employe back icon container */
      <div className="ss-employe-back-icon-container">
        <Link to='/doctors'>
        <img 
          src={backArrowIcon}
          onContextMenu={(e) => {e.preventDefault()}}
          alt="una-residence-poliklinika-back-arrow-icon"/>
        </Link>
      </div>

  )
}