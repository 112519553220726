import React from "react";
import './blog.css';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import LsHomeHeader from '../../components/ls-home/ls-home-header/ls-home-header.js';
import LsBlogPost from "../../components/ls-blog/ls-blog-post/ls-blog-post.js";
import LsBlogRightContainer from "../../components/ls-blog/ls-blog-right-container/ls-blog-right-container.js";
import LsHomeNewsletter from '../../components/ls-home/ls-home-newsletter/ls-home-newsletter.js';
import LsHomeFooter from '../../components/ls-home/ls-home-footer/ls-home-footer.js';
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import HeaderDoctors from '../../components/doctors/header-doctors/header-doctors.js';
import NavigationDropdownDoctors from "../../components/doctors/navigation-dropdown-doctors/navigation-dropdown-doctors.js";
import SsBlogPost from "../../components/ss-blog/ss-blog-post/ss-blog-post.js";
import NewsletterDoctors from '../../components/doctors/newsletter-doctors/newsletter-doctors.js';
import HomeFooter from '../../components/home/footer-home/footer-home.js';

import blogData from "../../assets/data/ls-blog/ls-blog-data.js";

import funnelIcon from '../../assets/blog/una-residence-poliklinika-funnel-icon.svg';
import defaultThumbnailImage from '../../assets/home/una-una-residence-default-thumbnail-image.webp';

export default function Blog() {

  // this state determines which posts will be visible at specific time
  const [field, setField] = React.useState('all fields');

  // array of objects that every object represent filter option
  const [fieldFilter, setFieldFilter] = React.useState([
    {
      fieldId: 1,
      fieldName: 'Dermatologija'
    },
    {
      fieldId: 2,
      fieldName: 'Estetska i rekonstruktivna hirurgija'
    },
    {
      fieldId: 3,
      fieldName: 'Laseri'
    },
    {
      fieldId: 4,
      fieldName: 'Anti-aging'
    },
    {
      fieldId: 5,
      fieldName: 'Epilacija'
    },
    {
      fieldId: 6,
      fieldName: 'Tretmani'
    },
    {
      fieldId: 7,
      fieldName: 'Saveti'
    },
    {
      fieldId: 8,
      fieldName: 'Kozmetika'
    },
    {
      fieldId: 9,
      fieldName: 'Razno'
    },
  ])

  // this function devide parameter array into more arrays and every of those arrays contain some number of objects which is defined with chunkSize.
  function devideArray(array, chunkSize) {
    const chunks = [];
    let id = 1; // giving id to every group of objects (row) so we can map over them.
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push({
        id: id++,
        data: array.slice(i, i + chunkSize)
      })
    }
    return chunks;
  }

  // this const contains arrays with 3 objects devided from fieldFilter array.
  const fieldFilterDevided = devideArray(fieldFilter, 3);

  // changing field state value and activeOption state value depending on which filter option is clicked and returning value 'all fields' to field state and empty string to activeOption state if option is clicked twice in row.
  function activateOption(parameter) {
    if (field === parameter) {
      setField('all fields');
      setActiveOption('');
    } else {
      setField(parameter);
      setActiveOption(parameter)
    }
  }

  // this const contains filtered array posts depending of which filter option is clicked.
  const filterPosts = blogData.filter((post) => {
    if (field === 'all fields') { // field in this row is state in begining of code
      return !post.field.includes('hidden');
    } else {
      return post.field.includes(field); // first field in row is filed from blog-data and second is state in begining of code
    }
  })

  // this const changes depending on which filter option is clicked, and is used to change style of a filter button.
  const [activeOption, setActiveOption] = React.useState('');

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false);

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // state value that determine filter list visibility
  const [filterList, setFilterList] = React.useState(false);

  // function changes filterList state value
  function showFilterList() {
    setFilterList((prevState) => {
      return (
        !prevState
      )
    })
  }

  // reload page function
  function reloadBlogPage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }

  // part of code that renders
  return (
    <>

      <Helmet>
        <title>Una Residence Poliklinika, Vojvode Milenka 8 Beograd</title>
        <meta
          name="description"
          content=
            "Una Residence Poliklinika, Laser centar za dermatologiju, estetsku i plastičnu hirurgiju, Cancela Center of Excellence, Vojvode Milenka 8, +381 66 6703018">
        </meta>
        <link rel="canonical" href="https://unaresidence.rs/"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      {/* large screen blog container */}
      <div className="ls-blog-container">

        <LsHomeHeader 
          reloadBlogPage={reloadBlogPage} // passing reloadBlogPage function to LsHomeHeader component
        />

        {/* large screen blog title container */}
        <div className="ls-blog-title-container">
          <p>Blog sekcija</p>
        </div>

        {/* large screen blog container container */}
        <div className="ls-blog-container-container">

          {/* large screen blog left container */}
          <div className="ls-blog-left-container">

            {/* large screen blog left container path */}
            <p className="ls-blog-left-container-path">
              <Link to='/' style={{textDecoration:'none', color: 'black'}}>
                Naslovna
              </Link> 
              <span style={{cursor: 'pointer'}} onClick={reloadBlogPage}> / Blog sekcija</span>
            </p>

            {/* large screen blog left filter container */}
            <div className="ls-blog-left-filter-container">

              {/* large screen blog left filter title */}
              <p className="ls-blog-left-filter-title">Izaberite kategoriju</p>

              {/* large screen blog left filter options container */}
              <div className="ls-blog-left-filter-options-container">
                {fieldFilterDevided.map((optionsRow) => {
                  return (
                    <div
                      className="ls-blog-left-filter-options-row-container"
                      key={optionsRow.id}>
                        {optionsRow.data.map((option) => {
                          return (
                            <div 
                              className={activeOption === option.fieldName ?
                                'ls-blog-left-filter-option-active-button' : 'ls-blog-left-filter-option-button'}
                              key={option.fieldId}
                              onClick={() => activateOption(option.fieldName)}>
                              <p>
                                {option.fieldName.charAt(0).toUpperCase()+option.fieldName.slice(1)}
                              </p>
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
              </div>
            </div>

            {/* large screen blog left post list container */}
            <div className="ls-blog-left-post-list-container">
            {filterPosts.map((post) => {
                return (
                  <LsBlogPost 
                    key={post.postId}
                    post={post}/>
                )
              })}
            </div>
          </div>

          <LsBlogRightContainer />
        </div>
        
        <LsHomeNewsletter />

        <LsHomeFooter />

        <ArrowUpIcon />
      </div>










      {/* small screen blog container */}
      <div className="ss-blog-container">
        
        <HeaderDoctors 
          showDropdown={showDropdown} // passing showDropdown function to HeaderDoctors component
          isActive={dropdown}  
        />

        {dropdown ?
        <NavigationDropdownDoctors 
          reloadBlogPage={reloadBlogPage} // passing reloadBlogPage function to HeaderDoctors component
        /> : 
        
        <>
        {/* small screen blog title 1 container */}
        <div className="ss-blog-title-1-container">
          {/* small screen blog title 1 container container */}
          <div className="ss-blog-title-1-container-container">
            
            <p>Blog sekcija</p>

            {/* small screen blog filter icon container */}
            <div className="ss-blog-filter-icon-container">
              <img 
                src={funnelIcon}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt='una-residence-poliklinika-filter-ikonica'
                onClick={showFilterList}/>
            </div>
          </div>
        </div>
        
        {filterList &&
        // small screen filter list container
        <div className="ss-filter-list-container">
          
          <p>Izaberite kategoriju:</p>

          {fieldFilter.map((option) => {
            return (
              <div 
                className="ss-filter-list-container-container"
                key={option.fieldId}
                onClick={() => activateOption(option.fieldName)}>
                  <div className={activeOption === option.fieldName ?
                    "ss-filter-list-checkbox-active" :
                    "ss-filter-list-checkbox"
                  }></div>
                  <p>{option.fieldName}</p>
              </div>
            )
          })}
          
        </div>
        }
        
        {/* small screen blog post list constainer */}
        <div className="ss-blog-post-list-container">

          {filterPosts.map((post) => {
            return (
              <SsBlogPost 
                key={post.postId}
                post={post}/>
            )
          })}
        </div>

        <NewsletterDoctors /> 

        <HomeFooter />

        <ArrowUpIcon />
        </>}
      </div>
    </>
  )
}