import React from "react";
import './navigation-dropdown-doctors.css';
import { Link } from "react-router-dom";

import NavigationDropdownListItemDoctors from "../navigation-dropdown-list-item-doctors/navigation-dropdown-list-item-doctors.js";
import NavigationDropdownIconsDoctors from "../navigation-dropdown-icons-doctors/navigation-dropdown-icons-doctors.js";

import NavigationDropdownIconFacebook from '../../../assets/home/home-navigation-dropdown-icon-facebook.svg'
import NavigationDropdownIconInstagram from '../../../assets/home/home-navigation-dropdown-icon-instagram.svg'
import NavigationDropdownIconYoutube from '../../../assets/home/home-navigation-dropdown-icon-youtube.svg'
import NavigationDropdownIconLinkedin from '../../../assets/home/home-navigation-dropdown-icon-linkedin.svg'
import NavigationDropdownIconGoogle from '../../../assets/home/home-navigation-dropdown-icon-google.svg'

export default function NavigationDropdownDoctors(props) {

  /* part of code that renders */
  return(
    <div>
      
      {/* small screen doctors navigation dropdown list container */}
      <div className="ss-doctors-navigatio-dropdown-list-container">
        <Link to='/' style={{textDecoration: 'none'}}>
          <NavigationDropdownListItemDoctors 
            title="Naslovna"
            reloadPage={props.reloadHomePage}/>
        </Link>

        <Link style={{textDecoration: 'none'}}>
          <NavigationDropdownListItemDoctors 
            title="O nama"/>
        </Link>

        <Link to='/services' style={{textDecoration: 'none'}}>
          <NavigationDropdownListItemDoctors 
            title="Usluge"
            reloadPage={props.reloadServicesPage}/>
        </Link>

        <Link to='/doctors' style={{textDecoration: 'none'}} >
          <NavigationDropdownListItemDoctors 
            title="Doktori"
            reloadPage={props.reloadDoctorPage}/> {/* passing reloadDoctorPage function from Doctors page to NavigationDropdownListItemDoctors component */}
        </Link>

        <Link to='/blog' style={{textDecoration: 'none'}} onClick={props.reloadBlogPage}>
          <NavigationDropdownListItemDoctors 
            title="Blog"/>
        </Link>

        <Link to='/contact' style={{textDecoration: 'none'}} onClick={props.reloadContactPage}>
          <NavigationDropdownListItemDoctors 
            title="Kontakt"
            reloadPage={props.reloadContactPage}/>
        </Link>
      </div>

      {/* small screen doctors navigation dropdown icons */}
      <div className="ss-doctors-navigation-dropdown-icons-container">
        <NavigationDropdownIconsDoctors 
          source={NavigationDropdownIconFacebook}
          alternative="una-residence-poliklinika-facebook-icon"
          url="https://www.facebook.com/unaresidence.rs"
        />
        <NavigationDropdownIconsDoctors 
          source={NavigationDropdownIconInstagram}
          alternative="una-residence-poliklinika-instagram-icon"
          url="https://www.instagram.com/unaresidence.rs/"
        />
        <NavigationDropdownIconsDoctors 
          source={NavigationDropdownIconYoutube}
          alternative="una-residence-poliklinika-youtube-icon"
          url="https://www.youtube.com/@unaresidence"
        />
        <NavigationDropdownIconsDoctors 
          source={NavigationDropdownIconLinkedin}
          alternative="una-residence-poliklinika-linkedin-icon"
          url="https://www.linkedin.com/company/unaresidence"
        />
        <NavigationDropdownIconsDoctors 
          source={NavigationDropdownIconGoogle}
          alternative="una-residence-poliklinika-google-icon"
          url="https://maps.app.goo.gl/9LbVyYCTe8U9fhvy7"
        />
      </div>

    </div>
  )
}