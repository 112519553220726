import React from "react";
import './ls-employe-profile.css';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import LsHomeHeader from '../../ls-home/ls-home-header/ls-home-header.js';
import LsEmployeProfileSlideshow from "../ls-employe-profile-slideshow/ls-employe-profile-slideshow.js";
import LsHomeNewsletter from '../../ls-home/ls-home-newsletter/ls-home-newsletter.js';
import LsHomeFooter from '../../ls-home/ls-home-footer/ls-home-footer.js';
import LsHomePart3Field from "../../ls-home/ls-home-part-3-field/ls-home-part-3-field.js";

import decoration1 from '../../../assets/home/home-team-decoration-1.svg';

import lsHomeServicesFieldData from "../../../assets/data/ls-home/ls-home-services-field-data.js";

export default function LsEmployeProfile({profile}) {

  // devide array function creates array with more arrays in it and every one of them containes number of objects represent in chunkSize
  function devideArray(array, chunkSize) {
    const chunks = [];
    let id = 1;
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push({
        id: id++,
        data: array.slice(i, i + chunkSize)
      })
    }
    return chunks;
  }
  
  // deviding  profile.gallery array into group of arrays with 3 objects
  const profileGalleryDevided = devideArray(profile.gallery, 3);
  
  // state value that determine slideshow visibility
  const [slideshowVisibility, setSlideshowVisibility] = React.useState(false)
  // state value that determine which will be the first image in slideshow
  const [startIndex, setStartIndex] = React.useState(0);

  // function is changing slideshow visibility to true and passing image index to startIndex state which is passed to DoctorProfileSlideshow component
  function openSlide(parameter) {
    setSlideshowVisibility(true);
    setStartIndex(parameter);
  }

  // function is changing slideshow visibility to false
  function closeSlide() {
    setSlideshowVisibility((prevState) => {
      return (
        prevState = false
      )
    })
  }

  // part of code that renders
  return (
    <>
      {/* large screen employe profile container */}
      <div className="ls-employe-profile-container">

        <LsHomeHeader />

        {/* large screen employe profile title container */}
        <div className="ls-employe-profile-title-container">
          <p>Una Residence tim</p>
        </div>

        {/* large screen employe profile container container */}
        <div className="ls-employe-profile-container-container">

          {/* large screen employe profile left container */}
          <div className="ls-employe-profile-left-container">
            
            {/* large screen employe profile left image & info container */}
            <div className="ls-employe-profile-left-image-info-container">

              {/* large screen employe profile left image container */}
              <div className="ls-employe-profile-left-image-container">
                <img 
                  src={profile.image}
                  onContextMenu={(e) => {e.preventDefault(e)}}
                  alt={profile.alternative}/>
              </div>

              {/* large screen employe profile left info container */}
              <div className="ls-employe-profile-left-info-container">
                <p className="ls-employe-profile-left-info-name">{profile.name}</p>
                <p className="ls-employe-profile-left-info-description">{profile.description}</p>
              </div>

              {/* large screen employe profile left decoration 1 */}
              <img 
                className="ls-employe-profile-left-decoration-1" 
                src={decoration1}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-residence-poliklinika-dekoracija"  
              />

              {/* large screen employe profile left decoration 2 */}
              <img 
                className="ls-employe-profile-left-decoration-2" 
                src={decoration1}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-residence-poliklinika-dekoracija"  
              />
            </div>

            {profile.services &&
            // large screen employe profile left services container
            <div className="ls-employe-profile-left-services-container">
              {profile.services.map((listItem) => {
                return (
                  <div 
                    className="ls-employe-profile-left-services-list-item-container"
                    key={listItem.id}>
                      <p>{listItem.text.charAt(0).toUpperCase() + listItem.text.slice(1)}</p>
                  </div>
                )
              })}
            </div>
            }

            {/* large screen employe profile left reservation button */}
            <Link to='/contact' style={{textDecoration: 'none'}}>
              <div className="ls-employe-profile-left-reservation-button">
                <p>Zakažite pregled</p>
              </div>
            </Link>

            {profile.gallery &&
            // large screen employe profile left gallery container
            <div className="ls-employe-profile-left-gallery-container">
              
              {/* large screen employe profile left horizontal rule */}
              <div className="ls-employe-profile-left-horizontal-rule"></div>

              <p className="ls-employe-profile-left-title">Galerija</p>

              {/* large screen employe profile left images-container */}
              <div className="ls-employe-profile-left-images-container">
                {profileGalleryDevided.map((row) => {
                  return (
                    <div 
                      className="ls-employe-profile-left-images-row-container"
                      key={row.id}>
                        {row.data.map((image) => {
                          return (
                          <img 
                            src={image.image} 
                            key={image.id}
                            onContextMenu={(e) => {e.preventDefault(e)}}
                            alt={image.alternative}
                            onClick={() => openSlide(image.id)} // passing image index as parametar to startIndex state
                          />
                          )
                        })}
                    </div>
                  )
                })}
              </div>

              {profile.videoLink &&
              // large screen employe profile left video container
              <div className="ls-employe-profile-left-video-container">
                <ReactPlayer 
                  url={profile.videoLink}
                  controls
                  width={43.333 + 'vw'}
                  height={30 + 'vw'}/>
              </div>
              }
            </div>
            }

            {slideshowVisibility &&
              <LsEmployeProfileSlideshow
                profileGallery={profile.gallery}  
                closeSlide={closeSlide}
                startIndex={startIndex}/>
            }
            
            {profile.biography &&
            // large screem employe profile left biography container  
            <div className="ls-employe-profile-left-biography-container">
              
              {/* large screen employe profile left horizontal rule */}
              <div className="ls-employe-profile-left-horizontal-rule"></div>

              <p className="ls-employe-profile-left-title">Biografija</p>

              {/* large screen employe profile left biography text container */}
              <div className="ls-employe-profile-left-biography-text-container">
                {profile.biography.map((part) => {
                  if (part.id === 'mainBio') {
                    return part.content.map((listItem) => {
                      return (
                        <p
                          className="ls-employe-profile-left-biography-text" 
                          key={listItem.id}>
                            {listItem.text}
                        </p>
                      )
                    })
                  } else if (part.id === 'education') {
                    return (
                      <div key={part.id}>
                        <p 
                          className="ls-employe-profile-left-title"
                          style={{marginTop: 0}}>
                            Obrazovanje
                        </p>
                        <ul>
                          {part.content.map((listItem) => {
                            return (
                              <li 
                                className="ls-employe-profile-left-biography-text"
                                key={listItem.id}>
                                  {listItem.text}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  } else if (part.id === 'experience') {
                    return (
                      <div key={part.id}>
                        <p 
                          className="ls-employe-profile-left-title"
                          style={{marginTop: 0}}>
                            Iskustvo
                        </p>
                        <ul>
                          {part.content.map((listItem) => {
                            return (
                              <li 
                                className="ls-employe-profile-left-biography-text"
                                key={listItem.id}>
                                  {listItem.text}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  } else if (part.id === 'membership') {
                    return (
                      <div key={part.id}>
                        <p 
                          className="ls-employe-profile-left-title"
                          style={{marginTop: 0}}>
                            Članstvo
                        </p>
                        <ul>
                          {part.content.map((listItem) => {
                            return (
                              <li 
                                className="ls-employe-profile-left-biography-text"
                                key={listItem.id}>
                                  {listItem.text}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
            }
          </div>

          {/* large screen employe profile right container */}
          <div className="ls-employe-profile-right-container">

            {/* large screen employe profile right container container */}
            <div className="ls-employe-profile-right-container-container">
              {lsHomeServicesFieldData.map((textGroup) => {
                return (
                  <LsHomePart3Field 
                    key={textGroup.id}
                    textGroup={textGroup}/>
                )
              })}
            </div>    
          </div>  
        </div>

        <LsHomeNewsletter marginTop={1.667} />

        <LsHomeFooter />
      </div>
    </>
  )
}