import React from "react";
import './ls-blog-small-post.css';
import { Link } from "react-router-dom";

export default function LsBlogSmallPost({post}) {
  // part of code that renderes
  return (
    <>
      <Link to={post.link} style={{textDecoration: 'none'}}>
        {/* large screen blog right small post container */}
        <div className="ls-blog-right-small-post-container">
          
          {/* large screen blog right small post image container */}
          <div className="ls-blog-right-small-post-image-container">
            <img 
              src={post.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={post.alternative}/>
          </div>

          {/* large screen blog right small post text container */}
          <div className="ls-blog-right-small-post-text-container">

            {/* large screen blog right small post title */}
            <p className="ls-blog-right-small-post-title">
              {post.title}
            </p>

            {/* large screen blog right small post paragraph */}
            <p className="ls-blog-right-small-post-paragraph">
              {post.paragraph}
            </p>

          </div>

        </div>
      </Link>
    </>
  )
}