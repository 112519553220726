import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import ScrollTop from "./components/scroll-top/scroll-top.js";
import Home from '../src/pages/home/home.js';
import Doctors from '../src/pages/doctors/doctors.js';
import Services from "./pages/services/services.js";
import Contact from "./pages/contact/contact.js";
import Blog from './pages/blog/blog.js'
import AnaMalivukovic from "./pages/ana-malivukovic/ana-malivukovic.js";
import BiljanaMitric from "./pages/biljana-mitric/biljana-mitric.js";
import ZeljkoStanojlovic from "./pages/zeljko-stanojlovic/zeljko-stanojlovic.js";
import BranislavLukic from "./pages/branislav-lukic/branislav-lukic.js";
import ZoranaGrubovic from "./pages/zorana-grubovic/zorana-grubovic.js";
import TathianaCastilloMaras from "./pages/tathiana-castillo-maras/tathiana-castillo-maras.js";
import MilenaMilovanovic from "./pages/milena-milovanovic/milena-milovanovic.js";
import MonaMalicevic from "./pages/mona-malicevic/mona-malicevic.js";
import OliveraZeljkovic from "./pages/olivera-zeljkovic/olivera-zeljkovic.js";
import MarijaAndric from "./pages/marija-andric/marija-andric.js";
import JelenaStojkovicfilipovic from "./pages/jelena-stojkovicfilipovic/jelena-stojkovicfilipovic.js";




import blogData from "./assets/data/ls-blog/ls-blog-data.js";


function App() {

  /* part of code that renders */
  return (
    <BrowserRouter>
    <ScrollTop />
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route path="/doctors" element={<Doctors />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/blog" element={<Blog />}/>
        <Route path="/ana-malivukovic" element={<AnaMalivukovic />}/>
        <Route path="/biljana-mitric" element={<BiljanaMitric />}/>
        <Route path="/zeljko-stanojlovic" element={<ZeljkoStanojlovic />}/>
        <Route path="/branislav-lukic" element={<BranislavLukic />}/>
        <Route path="/zorana-grubovic" element={<ZoranaGrubovic />}/>
        <Route path="/tathiana-castillo-maras" element={<TathianaCastilloMaras />}/>
        <Route path="/milena-milovanovic" element={<MilenaMilovanovic />}/>
        <Route path="/mona-malicevic" element={<MonaMalicevic />}/>
        <Route path="/olivera-zeljkovic" element={<OliveraZeljkovic />}/>
        <Route path="/marija-andric" element={<MarijaAndric />}/>
        <Route path="/jelena-stojkovic-filipovic" element={<JelenaStojkovicfilipovic />}/>



        {blogData.map((post) => {
          return (
            <Route key={post.postId} path={post.link} element={post.fileName} />
          )
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
