import React from "react";
import './ss-employe-profile.css';
import { Helmet } from "react-helmet";

import EmployeBackArrow from "../employe-back-arrow/employe-back-arrow";
import DoctorDoctors from "../../doctors/doctor-doctors/doctor-doctors";
import EmployeMakeReservation from "../employe-make-reservation/employe-make-reservation";
import EmployeNavigationIcons from "../employe-navigation-icons/employe-navigation-icons";
import EmployeServicesList from "../employe-services-list/employe-services-list";
import NewsletterDoctors from "../../doctors/newsletter-doctors/newsletter-doctors";
import FooterHome from "../../home/footer-home/footer-home";
import EmployeBiography from "../employe-biography/employe-biography";

import lsEmployeProfileData from "../../../assets/data/ls-employe-profile/ls-employe-profile-data"; 

export default function SsEmployeProfile(props) {

  const doctor = lsEmployeProfileData.find((doctor) => props.id === doctor.id);

  let helmetComponent = null;
  let h1Component = null;
  let h2Component = null;

  if (doctor) {
    helmetComponent = (
      <Helmet key={1}>
        <title>{doctor.seoInspector.title}</title>
        <meta
          name="description"
          content={doctor.seoInspector.metaContent}>
        </meta>
        <link rel="canonical" href={doctor.seoInspector.link}/>
        <meta property="og:image" content={doctor.seoInspector.thumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
    );

    h1Component = <h1 key={2} style={{display: 'none'}}>{doctor.seoInspector.h1}</h1>;
    h2Component = <h2 key={3} style={{display: 'none'}}>{doctor.seoInspector.h2}</h2>;
  }
  // part of code that renderes
  return (
    <>
      
      {helmetComponent}
      {h1Component}
      {h2Component}

      {/* small screen employe profile container */}
      <div className="ss-employe-profile-container">

        <EmployeBackArrow />

        {lsEmployeProfileData.map((doctor) => {
          if (props.id === doctor.id) {
            return (
              <DoctorDoctors 
                key={doctor.id}
                doctor={doctor}
                reloadPage={props.reloadPage}/>
            )
          }
        })}

        <EmployeMakeReservation 
            marginTop={1.478}/>

        <EmployeNavigationIcons 
          marginTop={5.911}/>

        {lsEmployeProfileData.map((doctor) => {
          if (props.id === doctor.id) {
            return (
              <EmployeServicesList
                key={doctor.id} 
                marginTop={5.911} 
                servicesList={doctor.services}/>
            )
          }
        })}

        

        {lsEmployeProfileData.map((doctor) => {
          if (props.id === doctor.id) {
            return (
              <EmployeBiography
                key={doctor.id} 
                marginTop={5.911} 
                biography={doctor.biography}/>
            )
          }
        })}

        <NewsletterDoctors 
          marginTop={5.911}/>

        <FooterHome />
      </div>
    </>
  )
}