import React from "react";
import './part-3.css';

import lsHomeServicesFieldData from "../../../assets/data/ls-home/ls-home-services-field-data";

import ServicesField from "../../services/services-field/services-field";
import { Link } from "react-router-dom";

export default function Part3(props) {
  /* part of code that renders */
  return (
    <>
      {/* small screen home part 3 container */}
      <div 
        className="ss-home-part-3-container"
        style={{backgroundColor: 'white'}}>

        {/* small screen home part 3 button container */}
        <Link to='services' style={{textDecoration: 'none'}}>
          <div className="ss-home-part-3-button-container">
            <p>Usluge</p>
          </div>
        </Link>

        {lsHomeServicesFieldData.map((field) => {
            if (field.id === 2) { // skip object with id 2 and render it last
              return false;
            } else {
              return (
                <ServicesField 
                  key={field.id}
                  field={field}/>
              )
            }
          })}

          {lsHomeServicesFieldData.map((field) => {
            if (field.id === 2) { // render only object with id 2
              return (
                <ServicesField 
                  key={field.id}
                  field={field}/>
              )
            }
          })}
      </div>
    </>
  )
}