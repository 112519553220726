import anaMalivukovic from '../../home/dr-ana-malivukovic.webp';
import branislavLukic from '../../home/dr-branislav-lukic.webp';
import zoranaGrubovic from '../../home/dr-zorana-grubovic.webp';
import tathianaCastilloMaras from '../../home/dr-tathiana-castillo-maras.webp';
import zeljkoStanojlovic from '../../home/dr-zeljko-stanojlovic.webp';
import biljanaMitric from '../../home/dr-biljana-mitric.webp';
import milenaMilovanovic from '../../home/dr-milena-milovanovic.webp';
import monaMalicevic from '../../home/dr-mona-malicevic.webp';
import oliveraZeljkovic from '../../home/dr-olivera-zeljkovic.webp';
import MarijaAndric from '../../home/dr-marija-andric.webp';
import JelenaStojkovicfilipovic from '../../home/dr-jelena-stojkovic-filipovic.webp';



import defaultImage from '../../employe/una-residence-poliklinika-default-avatar.webp';

const lsHomeTeamData = [
  {
    id: 1,
    name: 'dr Ana Malivuković',
    link: '/ana-malivukovic',
    image: anaMalivukovic,
    alternative: 'una-residence-poliklinika-ana-malivuković',
    description: 'specijalista plastične, estetske i rekonstruktivne hirurgije',
  },
  {
    id: 2,
    name: 'dr Branislav Lukić',
    link: '/branislav-lukic',
    image: branislavLukic,
    alternative: 'una-residence-poliklinika-branislav-lukić',
    description: 'specijalista oftalmologije - okuloplastike',
  },
  {
    id: 3,
    name: 'dr Zorana Grubović',
    link: '/zorana-grubovic',
    image: zoranaGrubovic,
    alternative: 'una-residence-poliklinika-zorana-grubović',
    description: 'specijalista plastične i rekonstruktivne hirurgije',
  },
  {
    id: 4,
    name: 'dr Tathiana Castillo Maraš',
    link: '/tathiana-castillo-maras',
    image: tathianaCastilloMaras,
    alternative: 'una-residence-poliklinika-tathiana-castillo-maraš',
    description: 'dermatovenerolog',
  },
  {
    id: 5,
    name: 'dr Željko Stanojlović',
    link: '/zeljko-stanojlovic',
    image: zeljkoStanojlovic,
    alternative: 'una-residence-poliklinika-željko-stanojlović',
    description: 'specijalista plastične i rekonstruktivne hirurgije',
  },
  {
    id: 6,
    name: 'dr Biljana Mitrić',
    link: '/biljana-mitric',
    image: biljanaMitric,
    alternative: 'una-residence-poliklinika-biljana-mitrić',
    description: 'specijalista plastične, rekonstruktivne i estetske hirurgije',
  },
  {
    id: 7,
    name: 'dr Milena Milovanović',
    link: '/milena-milovanovic',
    image: milenaMilovanovic,
    alternative: 'una-residence-poliklinika-milena-milovanović',
    description: 'specijalista dermatovenerologije',
  },
  {
    id: 8,
    name: 'dr Mona Maličević',
    link: '/mona-malicevic',
    image: monaMalicevic,
    alternative: 'una-residence-poliklinika-mona-maličević',
    description: 'specijalista mikroskopije i ćelijske biologije, regenerativne i anti-aging medicine',
  },
  {
    id: 10,
    name: 'dr Olivera Zeljković',
    link: '/olivera-zeljkovic',
    image: oliveraZeljkovic,
    alternative: 'una-residence-poliklinika-olivera-zeljković',
    description: 'doktor-opšte, anti-aging i estetske medicine',
  },
  {
    id: 11,
    name: 'dr Marija Andrić',
    link: '/marija-andric',
    image: MarijaAndric,
    alternative: 'una-residence-poliklinika-marija-andrić',
    description: 'Lekar kvantne medicine',
  },
  {
    id: 12,
    name: 'dr Jelena Stojković - Filipović',
    link: '/jelena-stojkovic-filipovic',
    image: JelenaStojkovicfilipovic,
    alternative: 'una-residence-poliklinika-jelena-stojkovic-filipovic',
    description: 'dermatovenerolog',
  },
]

export default lsHomeTeamData;