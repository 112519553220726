import defaultAvatar from '../../employe/una-residence-poliklinika-default-avatar.webp';

import post1Image1 from '../../post/post1/una-residence-post-image-1.jpg';
import post1Image2 from '../../post/post1/una-residence-post-image-2.jpg';
import post1Image3 from '../../post/post1/una-residence-post-image-3.png';
import post1Image4 from '../../post/post1/cenovnik-thumb.webp';
import post1Image5 from '../../post/post1/cenovnik.webp';


import cenovnik from '../../post/post1/cenovnik.svg';




import BlogPost1 from '../../../pages/blog-posts/blog-post-1.js';
import BlogPost2 from '../../../pages/blog-posts/blog-post-2.js';
import BlogPost3 from '../../../pages/blog-posts/blog-post-3.js';
import BlogPost4 from '../../../pages/blog-posts/blog-post-4.js';
import BlogPost5 from '../../../pages/blog-posts/blog-post-5.js';
import BlogPost6 from '../../../pages/blog-posts/blog-post-6.js';
import BlogPost7 from '../../../pages/blog-posts/blog-post-7.js';
import BlogPost8 from '../../../pages/blog-posts/blog-post-8.js';
import BlogPost9 from '../../../pages/blog-posts/blog-post-9.js';
import BlogPost10 from '../../../pages/blog-posts/blog-post-10.js';

// list of posts
const blogData = [
  {
    postId: 1,
    smallPostId: 1,
    link: '/candela-co2-laser',
    fileName: <BlogPost1/>, 
    mainTitle: 'Laseri', 
    postDate: '08.02.2024.',  
    image: post1Image3,
    alternative: 'Candela CO2RE - Nehirurško zatezanje kože',
    field: ['Laseri', 'Tretmani'],
    title: 'Candela CO2RE - Nehirurško zatezanje kože',
    paragraph: 
      'Candela CO2RE®️ - Nehirurško zatezanje kože, visoko izražena kolagenska stimulacija, redukcija ožiljaka i podmlađivanje kože, samo su neke od upotreba ovog moćnog lasera, Una Residence Poliklinika, +381 66 6703018',
    seoInspector: {
      title: 'Candela CO2RE - Nehirurško zatezanje kože',
      metaContent: 'Nehirurško zatezanje kože, visoko izražena kolagenska stimulacija, redukcija ožiljaka i podmlađivanje kože, Una Residence Poliklinika, +381 66 6703018',
      link: 'http://www.unaresidence.rs/candela-co2-laser',
      h1: 'Candela CO2RE - Nehirurško zatezanje kože, Una Residence Poliklinika, +381 66 6703018',
      h2: 'Nehirurško zatezanje kože, visoko izražena kolagenska stimulacija, redukcija ožiljaka i podmlađivanje kože, Una Residence Poliklinika, +381 66 6703018',
      thumbnailImage: post1Image2,
    },
    postContent1: [
      {
        id: 1,
        type: 'image',
        content: post1Image2,
        alternative: ''
      },
      {
        id: 2,
        type: 'title',
        content: 'Candela CO2RE laser - Nehirurško zatezanje kože'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Candela CO 2 Laser je ablativni laser sa visoko izraženom kolagenskom stimulacijom za redukciju ožiljaka i podmlađivanje kože (nehiruško zatezanje kože). Podmlađivanje i zatezanje kože, uklanjanje ožiljaka i strija, te za zatezanje viška kože kapaka i druge procedure u dermatologiji i plastičnoj hirurgiji. Veoma precizno i efikasno tretira najrazličitije promene na koži, ostavljajući okolno, zdravo tkivo netaknuto. Tako se postiže brže zarastanje kože i ubrzava period oporavka.',
          },
        ]
      },
      {
        id: 4,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Ožiljci od akni',
          },
          {
            id: 2,
            paragraph: 
              'Podmlađivanje kože',
          },
          {
            id: 3,
            paragraph: 
              'Fine linije i bore',
          },
          {
            id: 4,
            paragraph: 
              'Hipertofični ožiljci',
          },
          {
            id: 5,
            paragraph: 
              'Strije',
          },
          {
            id: 6,
            paragraph: 
              'Otklanjanje bradavica, kondiloma',
          },
          {
            id: 7,
            paragraph: 
              'Otklanjanje solarnog lentiga i staračkih fleka',
          },
          {
            id: 8,
            paragraph: 
              'Hirurgija, posebno blefaroplastika',
          },
        ],
      },
    ],
    reservationContainer: [ 
      {
        id: 1,
        content: post1Image1,
      },
      {
        id: 2,
        content: [
          {
            id: 1,
            paragraph: 'Dermatološki pregled',
          },
          {
            id: 2,
            paragraph: 'Lečenje akni',
          },
          {
            id: 3,
            paragraph: 'Laser tretmani',
          },
          {
            id: 4,
            paragraph: 'Estetska hirurgija',
          },
          {
            id: 5,
            paragraph: 'Uklanjanje tetovaža',
          },
          {
            id: 6,
            paragraph: 'Anti-aging',
          },
          {
            id: 7,
            paragraph: 'Rekonstruktivna hirurgija',
          },
        ]
      }
    ],
    postContent2: [ 
      {
        id: 1,
        type: 'title',
        content: 'Nehirurško podmlađivanje'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'CO 2 Laser veoma precizno i efikasno tretira najrazličitije promene na koži, ostavljajući okolno, zdravo tkivo netaknuto. Tako se postiže brže zarastanje kože i ubrzava period oporavka. Definitivni rezultati počinju od 4-6 nedelja nakon tretmana. Primenjuje se na koži celog tela. Procedura namenjena podjednako za muškarce i žene.',
          }
        ]
      },
    ],
    videoContent: [
      {
        id: 1,
        title: ''
      },
      {
        id: 2,
        link: 'https://www.youtube.com/shorts/9NqDKKOIJvQ',
      }
    ],
    team: [

    ],
  },



















  {
    postId: 2,
    smallPostId: 2,
    link: '/cenovnik',
    fileName: <BlogPost2/>, 
    mainTitle: 'Cenovnik', 
    postDate: '03.06.2024.',  
    image: post1Image4,
    alternative: 'Cenovnik',
    field: ['Laseri', 'Tretmani'],
    title: 'Cenovnik',
    paragraph: 
      'Cenovnik - Una Residence Poliklinika, +381 66 6703018',
    seoInspector: {
      title: 'Cenovnik - Una Residence Poliklinika',
      metaContent: 'Cenovnik - Una Residence Poliklinika, +381 66 6703018',
      link: 'http://www.unaresidence.rs/cenovnik',
      h1: 'Cenovnik - Una Residence Poliklinika, +381 66 6703018',
      h2: 'Cenovnik - Una Residence Poliklinika, +381 66 6703018',
      thumbnailImage: post1Image4,
    },
    postContent1: [

      {
        id: 1,
        type: 'title',
        content: 'Cenovnik - Una Residence Poliklinika'
      },
      {
        id: 2,
        type: 'image',
        content: post1Image5,
        alternative: ''
      },
    ],

    team: [

    ],
  },








































]

export default blogData;