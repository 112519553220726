import React from "react";
import './ls-home-header.css';
import { Link } from "react-router-dom";

import unaResidenceLogo from '../../../assets/home/una-poliklinika-logo.svg';

export default function LsHomeHeader(props) {
  // part of code that renders
  return (
    <>
      {/* large screen home header container */}
      <div className="ls-home-header-container">
        {/* large screen home header container container */}
        <div className="ls-home-header-container-container">

          {/* large screen home header logo container */}
          <Link to='/'>
          <div className="ls-home-header-logo-container">
            <img 
              src={unaResidenceLogo}
              onClick={props.reloadHomePage} // receiving RealoadHomePage from home.js page
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="Una Residence Poliklinika"
            />
          </div>
          </Link>

          {/* large screen home header navigation & button container */}
          <div className="ls-home-header-navigation-button-container">
            
            {/* large screen home header navigation container */}
            <ul className="ls-home-header-navigation-container">
              <Link 
                to="/" 
                style={{textDecoration: 'none'}}
                onClick={props.reloadHomePage}>
                <li>Naslovna</li>
              </Link>

              <Link to="/services" style={{textDecoration: 'none'}} onClick={props.reloadServicesPage}>
                <li>Usluge</li>
              </Link>
              
              <Link to="/doctors" style={{textDecoration: 'none'}} onClick={props.reloadDoctorPage}>
                <li>Tim</li>
              </Link>
            </ul>

            {/* large screen home header button container */}
            <div className="ls-home-header-button-container">
              {/* large screen home header button container */}
              <Link to="/contact" style={{textDecoration: 'none'}} onClick={props.reloadContactPage}>
                <div className="ls-home-header-button ls-home-header-contact-button">
                  <p>KONTAKT</p>
                </div>
              </Link>

              {/* large screen home header button container */}
              <Link to="/blog" style={{textDecoration: 'none'}} onClick={props.reloadBlogPage}>
                <div className="ls-home-header-button ls-home-header-blog-button">
                  <p>BLOG</p>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}