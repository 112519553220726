import React from "react";
import './ls-home-part-4-employe.css';
import { Link } from "react-router-dom";

import decorationImage from '../../../assets/home/home-team-decoration-1.svg';

export default function LsHomePart4Employe({employe}) {
  // part of code that renders
  return (
    <>
      {/* large screen home part 4 employe container */}
      <Link to={employe.link} style={{textDecoration: 'none'}}>
        <div className="ls-home-part-4-employe-container">

          {/* large screen home part 4 image container */}
          <div className="ls-home-part-4-image-container">
            <img 
              src={employe.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={employe.alternative}/>
          </div>

          {/* large screen home part 4 text container */}
          <div className="ls-home-part-4-text-container">

            {/* large screen home part 4 text name */}
            <p className="ls-home-part-4-text-name">
              {employe.name} {employe.surname}
            </p>

            {/* large screen home part 4 text description */}
            <p className="ls-home-part-4-text-description">
              {employe.description}
            </p>
          </div>

          {/* large screen home part 4 decoration container */}
          <div className="ls-home-part-4-decoration-container">
            <img 
              src={decorationImage}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt='una-residence-poliklinika-dekoracija'
              className="ls-home-part-4-decoration-1"/>

            <img 
              src={decorationImage}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt='una-residence-poliklinika-dekoracija'
              className="ls-home-part-4-decoration-2"/>     
           
          </div>
        </div>
      </Link>
    </>
  )
}