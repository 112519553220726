import React from "react";
import './ls-home-footer-social-media-icon.css';

export default function LsHomeFooterSocialMediaIcon(props) {
  // part of code that renders
  return (
    <>
      {/* large screen home footer social media icon container */}
      <a href={props.url}>
        <div 
          className="ls-home-footer-social-media-icon-container"
          style={{width: props.width + 'vw'}}>
            <img 
              src={props.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={props.alternative}/>
        </div>
      </a>
    </>
  )
}