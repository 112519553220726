import anaMalivukovic from '../../home/dr-ana-malivukovic.webp';
import branislavLukic from '../../home/dr-branislav-lukic.webp';
import zoranaGrubovic from '../../home/dr-zorana-grubovic.webp';
import tathianaCastilloMaras from '../../home/dr-tathiana-castillo-maras.webp';
import zeljkoStanojlovic from '../../home/dr-zeljko-stanojlovic.webp';
import biljanaMitric from '../../home/dr-biljana-mitric.webp';
import MilenaMilovanovic from '../../home/dr-milena-milovanovic.webp';
import monaMailicevic from '../../home/dr-mona-malicevic.webp';
import oliveraZeljkovic from '../../home/dr-olivera-zeljkovic.webp';
import MarijaAndric from '../../home/dr-marija-andric.webp';
import JelenaStojkovicfilipovic from '../../home/dr-jelena-stojkovic-filipovic.webp';



import defaultAvatar from '../../employe/una-residence-poliklinika-default-avatar.webp';

import oliverazeljkovicbio1 from '../../employe/una-residence-poliklinika-oliverazeljkovicbio1.webp';
import oliverazeljkovicbio2 from '../../employe/una-residence-poliklinika-oliverazeljkovicbio2.webp';
import oliverazeljkovicbio3 from '../../employe/una-residence-poliklinika-oliverazeljkovicbio3.webp';

import marijaandricbio1 from '../../employe/una-residence-poliklinika-marijaandricbio1.webp';
import marijaandricbio2 from '../../employe/una-residence-poliklinika-marijaandricbio2.webp';
import marijaandricbio3 from '../../employe/una-residence-poliklinika-marijaandricbio3.webp';

import jelenastojkovicfilipovicbio1 from '../../employe/una-residence-poliklinika-jelenastojkovicfilipovicbio1.webp';
import jelenastojkovicfilipovicbio2 from '../../employe/una-residence-poliklinika-jelenastojkovicfilipovicbio2.webp';
import jelenastojkovicfilipovicbio3 from '../../employe/una-residence-poliklinika-jelenastojkovicfilipovicbio3.webp';


import branislavlukicbio1 from '../../employe/una-residence-poliklinika-branislavlukicbio1.webp';
import branislavlukicbio2 from '../../employe/una-residence-poliklinika-branislavlukicbio2.webp';
import branislavlukicbio3 from '../../employe/una-residence-poliklinika-branislavlukicbio3.webp';
import branislavlukicbio4 from '../../employe/una-residence-poliklinika-branislavlukicbio4.webp';
import branislavlukicbio5 from '../../employe/una-residence-poliklinika-branislavlukicbio5.webp';
import branislavlukicbio6 from '../../employe/una-residence-poliklinika-branislavlukicbio6.webp';
import branislavlukicbio7 from '../../employe/una-residence-poliklinika-branislavlukicbio7.webp';
import branislavlukicbio8 from '../../employe/una-residence-poliklinika-branislavlukicbio8.webp';
import branislavlukicbio9 from '../../employe/una-residence-poliklinika-branislavlukicbio9.webp';



const lsEmployeProfileData = [
  {
    id: 'ana-malivukovic',
    image: anaMalivukovic,
    alternative: 'una-residence-poliklinika-ana-malivuković',
    name: 'dr Ana Malivuković',
    description: 'specijalista plastične, estetske i rekonstruktivne hirurgije',
    link: 'https://www.unaresidence.rs/ana-malivukovic',
    seoInspector: {
      title: 'dr Ana Malivuković, Una Residence Poliklinika, +381 66 6703018',
      metaContent: 'dr Ana Malivuković, specijalista plastične, estetske i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/ana-malivukovic',
      thumbnailImage: anaMalivukovic,
      h1: 'dr Ana Malivuković, Una Residence Poliklinika, +381 66 6703018',
      h2: 'dr Ana Malivuković, specijalista plastične, estetske i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'rekonstrukcija dojke'
      },
      { 
        id: 2,
        text: 'estetska hirurgija dojke (povećanje, smanjenje, podizanje dojke)'
      },
      {
        id: 4,
        text: 'estetska hirurgija lica (lifting, rinoplastika, blefaroplastika, liposukcija vrata itd.)'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      }
    ],
    videoLink: 'https://www.youtube.com/watch?v=sihEdy_nxlU',
    biography: [
      {
        id: 'mainBio',
        content: [
          {
            id: 1,
            text: 
              'Dr Ana Malivuković je stručnjak za plastičnu, rekonstruktivnu i estetsku hirurgiju. Diplomirala je na Medicinskom fakultetu Univerziteta u Beogradu 2012. godine. Nakon toga je završila specijalizaciju iz oblasti plastične, rekonstruktivne i estetske hirurgije na Vojnomedicinskoj akademiji.'
          },
          {
            id: 2,
            text: 
              'Dr Ana Malivuković je takođe upisala doktorske studije na Medicinskom fakultetu u Beogradu 2014. godine, fokusirajući se na rekonstruktivnu hirurgiju. Njen rad obuhvata područja rekonstrukcije dojke, rekonstruktivne hirurgije glave i vrata, kao i estetske hirurgije tela i lica. Takođe je stekla mikrohirurški trening i veštine. Poseduje značajno iskustvo u antiaging medicini.'
          },
          {
            id: 3,
            text: 
              'Dr Ana Malivuković je pohađala specijalizovane kurseve i radila na klinikama i institutima u inostranstvu, uključujući Sant’Andrea Rim na Univerzitetu "Sapienza" u Italiji i Klinički centar Slovenije u Ljubljani.'
          },
          {
            id: 4,
            text: 
              'Uz svoje profesionalno angažovanje, dr Ana Malivuković je učestvovala na mnogim kongresima, dobila stipendije i nagrade, te je autorka medicinskih publikacija.'
          },
        ],
      },
    ], 
  },










  {
    id: 'branislav-lukic',
    image: branislavLukic,
    alternative: 'una-residence-poliklinika-branislav-lukić',
    name: 'dr Branislav Lukić',
    description: 'specijalista oftalmologije - okuloplastike',
    link: 'https://www.unaresidence.rs/branislav-lukic',
    seoInspector: {
      title: 'dr Branislav Lukić, okuloplastičar, +381 66 6703018',
      metaContent: 'dr Branislav Lukić, specijalista oftalmologije - okuloplastike, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/branislav-lukic',
      thumbnailImage: branislavLukic,
      h1: 'dr Branislav Lukić, okuloplastičar, +381 66 6703018',
      h2: 'dr Branislav Lukić, specijalista oftalmologije - okuloplastike, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'botoks tretmani'
      },
      { 
        id: 2,
        text: 'hijaluronska kiselina'
      },
      {
        id: 3,
        text: 'mezoterapija'
      },
      {
        id: 4,
        text: 'PRP - plazma bogata trombocitima'
      },
      {
        id: 5,
        text: 'hemijski piling'
      },
      {
        id: 6,
        text: 'Nordlys laser tretmani'
      },
      {
        id: 7,
        text: 'Picoway laser tretmani'
      },
      {
        id: 8,
        text: 'aptos mezoniti'
      },
      {
        id: 9,
        text: 'kolagen stimulator tretmani'
      },
      {
        id: 10,
        text: 'endolift face lifting'
      },
      {
        id: 11,
        text: 'Z Tone tretmani'
      },
      {
        id: 12,
        text: 'CO2RE laser tretmani'
      },
      {
        id: 13,
        text: 'Profound Matrix tretmani'
      },
    ],
    gallery: [
      {
        id: 0,
        image: branislavlukicbio1,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 1,
        image: branislavlukicbio2,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 2,
        image: branislavlukicbio3,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 3,
        image: branislavlukicbio4,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 4,
        image: branislavlukicbio5,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 5,
        image: branislavlukicbio6,
        alternative: 'una-residence-poliklinika',
      }
    ],
    videoLink: 'https://youtu.be/6T9YmW5S_eE',
    biography: [
      {
        id: 'mainBio',
        content: [
          {
            id: 1,
            text: 
              'Sertifikovani lekar estetske medicine i specijalista oftalmologije. Potpredsednik SESIAM udruženja i član Izvršnog odbora. KOL i international speaker za CANDELA'
          }
        ],
      },

      {
        id: 'experience',
        content: [
          {
            id: 1,
            text: 
              "Sertifikovani lekar estetske medicine. Sertifikati o estetskoj medicini priznatih od Ministarstva zdravlja i lekarske komore Srbije"
          },
          {
            id: 2,
            text: 
              "Fileri hialuronske kiseline za dermalnu upotrebu"
          },
          {
            id: 3,
            text: 
              "Anti-aging"
          },
          {
            id: 4,
            text: 
              "Primena Botulin – toxin -a protiv bora, hiperhidroze, migrene i nevoljnih trzaja mišića"
          },
          {
            id: 5,
            text: 
              "Mezoterapija za lice i telo"
          },
          {
            id: 6,
            text: 
              "PRP tretmani za “antiaging”, povrede mišića i za intimnu – urogenitalnu regiju"
          },
          {
            id: 7,
            text: 
              "Različiti tretmani estetskim laserima i kozmetičkim mašinama"
          },
          {
            id: 8,
            text: 
              "Laserske procedure Nordlys i Pico way"
          },
          {
            id: 9,
            text: 
              "Mezoniti za lice i telo"
          },
        ],
      },
    ],
  },










  {
    id: 'zorana-grubovic',
    image: zoranaGrubovic,
    alternative: 'una-residence-poliklinika-zorana-grubović',
    name: 'dr Zorana Grubović',
    description: 'specijalista plastične i rekonstruktivne hirurgije',
    link: 'https://www.unaresidence.rs/zorana-grubovic',
    seoInspector: {
      title: 'dr Zorana Grubović, Una Residence Poliklinika, +381 66 6703018',
      metaContent: 'dr Zorana Grubović, specijalista plastične i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/zorana-grubovic',
      thumbnailImage: zoranaGrubovic,
      h1: 'dr Zorana Grubović, Una Residence Poliklinika, +381 66 6703018',
      h2: 'dr Zorana Grubović, specijalista plastične i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'Pixel CO2 laser'
      },
      { 
        id: 2,
        text: 'MeDioStar NeXT laser'
      },
      {
        id: 3,
        text: 'Quantum DL IPL Syestem laser'
      },
      {
        id: 4,
        text: 'PRP - plazma bogata trombocitima'
      },
      {
        id: 5,
        text: 'Light Sheer System laser'
      },
      {
        id: 6,
        text: 'D Light System laser'
      },
      {
        id: 7,
        text: 'ClearLight System laser'
      },
      {
        id: 8,
        text: 'Lumenis laser'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      }
    ],
    videoLink: 'https://www.youtube.com/shorts/9NqDKKOIJvQ',
    biography: [

    ],
  },










  {
    id: 'tathiana-castillo-maras',
    image: tathianaCastilloMaras,
    alternative: 'una-residence-poliklinika-tathiana-castillo-maraš',
    name: 'dr Tathiana Castillo Maraš',
    description: 'dermatovenerolog',
    link: 'https://www.unaresidence.rs/tathiana-castillo-maras',
    seoInspector: {
      title: 'dr Tathiana Castillo Maraš, dermatovenerolog, +381 66 6703018',
      metaContent: 'dr Tathiana Castillo Maraš, dermatovenerolog, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/tathiana-castillo-maras',
      thumbnailImage: tathianaCastilloMaras,
      h1: 'dr Tathiana Castillo Maraš, dermatovenerolog, +381 66 6703018',
      h2: 'dr Tathiana Castillo Maraš, dermatovenerolog, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'dermatološke promene'
      },
      { 
        id: 2,
        text: 'biopsija kože'
      },
      {
        id: 3,
        text: 'hirurško i radiotalasno uklanjanje manjih promena kože'
      },
      {
        id: 4,
        text: 'lečenje rozacee'
      },
      {
        id: 5,
        text: 'lečenje psorijaze'
      },
      {
        id: 6,
        text: 'lečenje alopecije'
      },
      {
        id: 7,
        text: 'lečenje oboljenja noktiju'
      },
      {
        id: 8,
        text: 'ekcema'
      },
      {
        id: 9,
        text: 'vitiligo'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      }
    ],
    videoLink: 'https://www.youtube.com/shorts/9NqDKKOIJvQ',
    biography: [

    ],
  },










  {
    id: 'zeljko-stanojlovic',
    image: zeljkoStanojlovic,
    alternative: 'una-residence-poliklinika-željko-stanojlović',
    name: 'dr Željko Stanojlović',
    description: 'specijalista plastične i rekonstruktivne hirurgije',
    link: 'https://www.unaresidence.rs/zeljko-stanojlovic',
    seoInspector: {
      title: 'dr Željko Stanojlović, Una Residence Poliklinika, +381 66 6703018',
      metaContent: 'dr Željko Stanojlović, specijalista plastične i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/zeljko-stanojlovic',
      thumbnailImage: zeljkoStanojlovic,
      h1: 'dr Željko Stanojlović, Una Residence Poliklinika, +381 66 6703018',
      h2: 'dr Željko Stanojlović, specijalista plastične i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'podmalđivanje lica'
      },
      { 
        id: 2,
        text: 'hirurgija dojke'
      },
      {
        id: 3,
        text: 'liposkulptura'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      }
    ],
    videoLink: 'https://www.youtube.com/shorts/9NqDKKOIJvQ',
    biography: [

    ],
  },










  {
    id: 'biljana-mitric',
    image: biljanaMitric,
    alternative: 'una-residence-poliklinika-biljana-mitric',
    name: 'dr Biljana Mitrić',
    description: 'specijalista plastične, rekonstruktivne i estetske hirurgije',
    link: 'https://www.unaresidence.rs/biljana-mitric',
    seoInspector: {
      title: 'dr Biljana Mitrić, Una Residence Poliklinika, +381 66 6703018',
      metaContent: 'dr Biljana Mitrić, specijalista plastične i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/biljana-mitric',
      thumbnailImage: biljanaMitric,
      h1: 'dr Biljana Mitrić, Una Residence Poliklinika, +381 66 6703018',
      h2: 'dr Biljana Mitrić, specijalista plastične i rekonstruktivne hirurgije, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'rekonstrukcija dojke'
      },
      { 
        id: 2,
        text: 'rekonstrukcija lica'
      },
      {
        id: 3,
        text: 'rekonstrukcija vrata'
      },
      {
        id: 4,
        text: 'rekonstrukcija ekstremiteta'
      },
      {
        id: 5,
        text: 'liposukcija'
      },
      {
        id: 6,
        text: 'abdominoplastika'
      },
      {
        id: 7,
        text: 'povećanje i smanjenje dojki'
      },
      {
        id: 8,
        text: 'korekcija nosa'
      },
      {
        id: 9,
        text: 'podizanje lica i vrata'
      },
      {
        id: 10,
        text: 'korigovanje ožiljaka'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika',
      }
    ],
    videoLink: 'https://www.youtube.com/shorts/9NqDKKOIJvQ',
    biography: [
    ],
  },









  {
    id: 'milena-milovanovic',
    image: MilenaMilovanovic,
    alternative: 'una-residence-poliklinika-milena-milovanović',
    name: 'dr Milena Milovanović',
    description: 'specijalista dermatovenerologije',
    link: '/milena-milovanovic',
    seoInspector: {
      title: 'dr Milena Milovanović, dermatovenerolog, Una Residence Poliklinika',
      metaContent: 'dr Milena Milovanović, dermatovenerolog, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/milena-milovanovic',
      thumbnailImage: MilenaMilovanovic,
      h1: 'dr Milena Milovanović, dermatovenerolog, Una Residence Poliklinika',
      h2: 'dr Milena Milovanović, dermatovenerolog, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'Dermatološki pregled'
      },
      { 
        id: 2,
        text: 'Lečenje akni'
      },
      {
        id: 3,
        text: 'Uklanjanje mladeža'
      },
      {
        id: 4,
        text: 'Dermoskopija'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-',
      }
    ],
    videoLink: 'https://www.youtube.com/shorts/Q7rwGucvlAM',
    biography: [
    ],
  },









  {
    id: 'mona-malicevic',
    image: monaMailicevic,
    alternative: 'una-residence-poliklinika-mona-maličević',
    name: 'dr Mona Maličević',
    description: 'specijalista mikroskopije i ćelijske biologije, regenerativne i anti-aging medicine',
    link: 'https://www.unaresidence.rs/mona-malicevic',
    seoInspector: {
      title: 'dr Mona Maličević, anti-aging, Una Residence Poliklinika, +381 66 6703018',
      metaContent: 'dr Mona Maličević, anti-aging, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/mona-malicevic',
      thumbnailImage: monaMailicevic,
      h1: 'dr Mona Maličević, anti-aging, Una Residence Poliklinika, +381 66 6703018',
      h2: 'dr Mona Maličević, anti-aging, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'Revitalizacija'
      },
      { 
        id: 2,
        text: 'Podmlađivanje lica'
      },
      {
        id: 3,
        text: 'Botoks tretmani'
      },
      {
        id: 4,
        text: 'Laser tretmani'
      },
    ],
    gallery: [
      {
        id: 0,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-mona-maličević',
      },
      {
        id: 1,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-mona-maličević',
      },
      {
        id: 2,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-mona-maličević',
      },
      {
        id: 3,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-mona-maličević',
      },
      {
        id: 4,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-mona-maličević',
      },
      {
        id: 5,
        image: defaultAvatar,
        alternative: 'una-residence-poliklinika-mona-maličević',
      }
    ],
    videoLink: 'https://www.youtube.com/shorts/9NqDKKOIJvQ',
    biography: [
        ],
  },





















  {
    id: 'olivera-zeljkovic',
    image: oliveraZeljkovic,
    alternative: 'una-residence-poliklinika-olivera-zeljković',
    name: 'dr Olivera Zeljković',
    description: 'Specijalizant plastične i rekonstruktivne hirurgije',
    link: 'https://www.unaresidence.rs/olviera-zeljkovic',
    seoInspector: {
      title: 'dr Olivera Zeljković, anti-aging, +381 66 6703018',
      metaContent: 'dr Olivera Zeljković, anti-aging, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/olivera-zeljkovic',
      thumbnailImage: oliveraZeljkovic,
      h1: 'dr Olivera Zeljković, anti-aging, +381 66 6703018',
      h2: 'dr Olivera Zeljković, anti-aging, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'Anti-aging'
      },
      { 
        id: 2,
        text: 'Laser tretmani'
      },
      {
        id: 3,
        text: 'Estetska medicina'
      },
      {
        id: 4,
        text: 'Mezoterapija'
      },
      {
        id: 5,
        text: 'Biorevitalizacija'
      },
      {
        id: 6,
        text: 'Botoks'
      },
      {
        id: 7,
        text: 'Niti'
      },

    ],
    gallery: [
      {
        id: 0,
        image: oliverazeljkovicbio1,
        alternative: 'una-residence-poliklinika-olivera-zeljković',
      },
      {
        id: 1,
        image: oliverazeljkovicbio2,
        alternative: 'una-residence-poliklinika-olivera-zeljković',
      },
      {
        id: 2,
        image: oliverazeljkovicbio3,
        alternative: 'una-residence-poliklinika-olivera-zeljković',
      }
    ],
    videoLink: 'https://www.youtube.com/watch?v=2XhFHViuqPs',
    biography: [

      {
        id: 'mainBio',
        content: [
          {
            id: 1,
            text: 
              'Dr Olivera Zeljković je završila medicinski fakultet u Beogradu, specijalizant je plastične i rekonstruktivne hirurgije. Posebno polje interesovanja je anti-aging, estetska medicina i laseri, uz fokus na minimalno - invazivne estetske procedure (mezoterapija, biorevitalizacija, botox, hijaluronski fileri i niti), i edukaciju u radu na savremenim laserskim platformama. Učesnik je internacionalnih i domaćih kongresa iz navedenih oblasti.'
          }
        ],
      },
    ],
  },











  {
    id: 'marija-andric',
    image: MarijaAndric,
    alternative: 'una-residence-poliklinika-marija-andrić',
    name: 'dr Marija Andrić',
    description: 'Lekar kvantne medicine',
    link: 'https://www.unaresidence.rs/marija-andric',
    seoInspector: {
      title: 'dr Marija Andrić, kvantna medicina, +381 66 6703018',
      metaContent: 'dr Marija Andrić, kvantna medicina, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/marija-andric',
      thumbnailImage: MarijaAndric,
      h1: 'dr Marija Andrić, kvantna medicina, +381 66 6703018',
      h2: 'dr Marija Andrić, kvantna medicina, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'Analizira homeostaze organa'
      },
      { 
        id: 2,
        text: 'Provera biohemijskih parametara krvi'
      },
      {
        id: 3,
        text: 'Terapija biorezonancom'
      },
      {
        id: 4,
        text: 'Otkrivanje i lečenje nepodnošljivosti hrane'
      },
      {
        id: 5,
        text: ' Lečenje infekcije virusom, bakterijama i parazitima'
      },
      {
        id: 6,
        text: 'Lečenje svih vrsta zavisnosti'
      },

    ],
    gallery: [
      {
        id: 0,
        image: marijaandricbio1,
        alternative: 'una-residence-poliklinika-marija-andrić',
      },
      {
        id: 1,
        image: marijaandricbio2,
        alternative: 'una-residence-poliklinika-marija-andrić',
      },
      {
        id: 2,
        image: marijaandricbio3,
        alternative: 'una-residence-poliklinika-marija-andrić',
      }
    ],
    videoLink: 'https://youtube.com/shorts/Y57f9Aq47cA?feature=share',
    biography: [

      {
        id: 'mainBio',
        content: [
          {
            id: 1,
            text: 
              'Dr Marija Andrić je završila Medicinski fakultet u Beogradu 2005.godine, kao i edukacije iz oblasti akupunkture, aromaterapije, fitoterapije, informoterapije. Od 2017. je lekar kvantne medicine sa poznavanjem homeopatije i mikronutricije.'
          },
        ],
      },
    ],
  },






  


  {
    id: 'jelena-stojkovicfilipovic',
    image: JelenaStojkovicfilipovic,
    alternative: 'una-residence-poliklinika-jelena-stojković-filipović',
    name: 'dr Jelena Stojković - Filipović',
    description: 'dermatovenerolog',
    link: 'https://www.unaresidence.rs/jelena-stojkovic-filipovic',
    seoInspector: {
      title: 'dr Jelena Stojković - Filipović, dermatologija, +381 66 6703018',
      metaContent: 'dr Jelena Stojković - Filipović, dermatologija, Una Residence Poliklinika, +381 66 6703018',
      link: 'https://www.unaresidence.rs/jelena-stojkovic-filipovic',
      thumbnailImage: JelenaStojkovicfilipovic,
      h1: 'dr Jelena Stojković - Filipović, dermatologija, +381 66 6703018',
      h2: 'dr Jelena Stojković - Filipović, dermatologija, Una Residence Poliklinika, +381 66 6703018',
    },
    services: [
      {
        id: 1,
        text: 'dermatološke promene'
      },
      { 
        id: 2,
        text: 'biopsija kože'
      },
      {
        id: 3,
        text: 'hirurško i radiotalasno uklanjanje manjih promena kože'
      },
      {
        id: 4,
        text: 'lečenje rozacee'
      },
      {
        id: 5,
        text: 'lečenje psorijaze'
      },
      {
        id: 6,
        text: 'lečenje alopecije'
      },
      {
        id: 7,
        text: 'lečenje oboljenja noktiju'
      },
      {
        id: 8,
        text: 'ekcema'
      },
      {
        id: 9,
        text: 'vitiligo'
      },

    ],
    gallery: [
      {
        id: 0,
        image: jelenastojkovicfilipovicbio1,
        alternative: 'una-residence-jelena-stojković-filipović',
      },
      {
        id: 1,
        image: jelenastojkovicfilipovicbio2,
        alternative: 'una-residence-jelena-stojković-filipović',
      },
      {
        id: 2,
        image: jelenastojkovicfilipovicbio3,
        alternative: 'una-residence-jelena-stojković-filipović',
      }
    ],
    videoLink: 'https://www.youtube.com/watch?v=TLhSuq2k1Dk',
    biography: [

      {
        id: 'mainBio',
        content: [
          {
            id: 1,
            text: 
              'Profesor dr Jelena Stojković - Filipović je završila Medicinski fakultet Univerziteta u Beogradu 2000. godine. Specijalizaciju iz dermatovenerologije završila je na Institutu za dermatovenerologiju Kliničkog Centra Srbije 2006. godine sa odličnim uspehom i od tada je stalno zaposlena u ovoj instituciji. Magistarske studije završila je 2009. godine, potom doktrorske studije 2016. godine na Medicinskom fakultetu, Univerziteta u Beogradu. Autor je više stručnih radova publikovanih na nacionalnim i međunarodnim stručnim skupovima.'
          },
        ],
      },
    ],
  },




  
]

export default lsEmployeProfileData;